<template>
    <header class="header">
        <div class="container">
            <div class="header__container">
                <div class="header__logo">
                    <z-logo site-dir="/ru/" :is-index="isIndex"/>
                </div>
                <div class="header__wrapper">
                    <div class="hide-me--v-tablet">
                        <navigation-main />
                    </div>
                    <div class="hide-me--default show-me--v-tablet">
                        <navigation-mobile />
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
import NavigationMain from '@/components/NavigationMain'
import NavigationMobile from '@/components/NavigationMobile'
import { computed } from 'vue';

const props = defineProps({
    url: {
        type: String
    }
})

const isIndex = computed(() => {
    if (props.url) {
        return props.url === '/'
    }

    return false
})
</script>

<style lang="scss">
.header {
    &__container {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 82px;
        padding-bottom: 82px;

        @include breakpoint (tablet) {
            padding-top: 45px;
            padding-bottom: 45px;
        }

        @include breakpoint (v-tablet) {
            padding-top: 25px;
            padding-bottom: 25px;
        }

        @include breakpoint (mobile) {
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
    }

    &__logo {
        align-self: flex-start;
        flex-shrink: 0;

        .z-logo {
            max-height: none;

            &__link {
                display: block;
            }

            img {
                max-width: 150px;
                max-height: 150px;

                @include breakpoint (tablet) {
                    max-width: 100px;
                }

                @include breakpoint (v-tablet) {
                    max-width: 80px;
                }

                @include breakpoint (mobile) {
                    max-width: 60px;
                }
            }

        }
    }

    &.is-open {
        position: relative !important;
        width: 100%;
        background-color: $token-colors-form-bg-30;
    }
}
</style>
