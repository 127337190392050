<template>
    <div class="z-share">
        <div class="z-share__trigger"
             :class="{'z-share__trigger--opened': isOpened}"
             ref="share"
        >
            <slot name="trigger" />
            <span v-if="showText" class="u-bold" v-html="text.share"></span>
        </div>
        <div class="z-share__content" ref="content">
            <div class="z-share__title">
                <z-icon name="tools/share" color="#1092dc" width="17" height="17" />
                Поделиться ссылкой
            </div>
            <div ref="ya">
            </div>
        </div>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'
import { localize } from '@/utils/i18n'
import tippy from 'tippy.js'

export default {
    name: 'z-share',
    props: {
        placement: {
            type: String,
            validator: prop => ['top', 'top-start', 'top-end', 'right', 'right-start', 'right-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end', 'auto', 'auto-start', 'auto-end'].includes(prop),
            default: 'right-start'
        },
        showText: {
            type: Boolean,
            default: false
        },
        services: {
            type: Array,
            required: true
        },
        url: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: undefined
        },
        trigger: {
            type: String,
            validator: prop => ['click', 'mouseenter', 'focus'].includes(prop),
            default: 'click'
        }
    },
    data () {
        return {
            isOpened: false,
            urlYaShare: 'https://yastatic.net/share2/share.js',
            isYaLoaded: false,
            text: {
                share: localize({
                    ru: 'Поделиться',
                    en: 'Share'
                })
            }
        }
    },
    mixins: [mixinDevice],
    mounted () {
        this.initTippy()
        this.initYaShare()
    },
    computed: {
        getEventForDevice () {
            if (this.device === 'mobile' || this.device === 'v-tablet') {
                return 'click'
            } else {
                return this.trigger
            }
        }
    },
    methods: {
        async initYaShare () {
            await this.addYaScript()
            if (this.isYaLoaded) {
                window.Ya.share2(this.$refs.ya, {
                    content: {
                        url: this.url || window.location.href,
                        title: this.title || document.title,
                        description: this.description,
                        image: this.image
                    },
                    theme: {
                        bare: true, // отключает стили библиотеки
                        lang: localize({
                            ru: 'ru',
                            en: 'en'
                        }),
                        services: this.services.join(',')
                    }
                })
            }
        },
        addYaScript () {
            const shareScript = document.querySelector('script[src="https://yastatic.net/share2/share.js"]')
            return new Promise((resolve) => {
                if (!shareScript) {
                    const script = document.createElement('script')
                    script.async = true
                    script.src = this.urlYaShare
                    document.head.appendChild(script)
                    script.onload = () => {
                        this.isYaLoaded = true
                        resolve()
                    }
                }
            })
        },
        initTippy () {
            const self = this
            tippy(this.$refs.share, {
                content: this.$refs.content,
                placement: this.placement,
                arrow: false,
                trigger: this.getEventForDevice,
                interactive: true,
                appendTo: 'parent',
                theme: 'share',
                onShow () {
                    self.isOpened = true
                },
                onHide () {
                    self.isOpened = false
                }
            })
        }
    }
}
</script>
<style lang="scss" src="./index.scss"></style>
