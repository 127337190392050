<template>
    <article class="detail-page" itemscope itemtype="https://schema.org/Article">
        <meta
            v-if="date"
            itemprop="datePublished"
            content="formatDate(date, 'YYYY-MM-DD')"
        >

        <slot name="header"/>
        <time
            v-if="date"
            class="detail-page__date"
            v-html="formatDate(date)"
            :datetime="formatDate(date, 'YYYY-MM-DD')"
        ></time>

        <div class="detail-page__content html-content" itemprop="articleBody">
            <vue-raw :raw="detail"/>
        </div>

        <slot name="footer"/>

        <div class="row">
            <div class="col-default-10 col-default-offset-1 col-v-tablet-12 col-v-tablet-offset-0">
                <z-page-nav
                    v-if="nav"
                    :data="nav"
                    button-text="На главную"
                    class="u-top-margin--2xl"
                    is-detail-page
                />
            </div>
        </div>

    </article>
</template>

<script setup>
import { momentFilter } from '@/utils/filter';
import { useStore } from 'vuex';

const props = defineProps({
    name: String,
    date: [String, Number],
    detail: String,
    nav: Object,
})

const store = useStore();

const formatDate = (date, pattern = 'DD MMMM YYYY') => {
    return date ? `${momentFilter(date, pattern)}` : date;
};

if (props.name) {
    store.dispatch('SetHeroTitle', props.name);
}
</script>
<style lang="scss">
.detail-page {
    padding-left: $token-spacers-m;
    padding-right: $token-spacers-m;

    @include breakpoint(v-tablet) {
        padding-left: $token-spacers-s;
        padding-right: $token-spacers-s;
    }

    @include breakpoint(mobile) {
        padding-left: $token-spacers-2-xs;
        padding-right: $token-spacers-2-xs;
    }

    &__date {
        color: $token-colors-black-40;
        display: block;
        margin-bottom: 32px;
        @include typo-level(XS);
    }
}
</style>
