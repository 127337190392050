<template>
    <div class="header-menu-wrapper">
        <nav class="header-menu-nav-links">
            <ul class="header-menu-nav-links__list">
                <li class="header-menu-nav-links__list-item"
                    v-for="(item, index) in data"
                    :key="index"
                >
                    <a
                        :href="item.link"
                        class="header-menu-nav-links__item-link"
                    >
                        <span v-html="item.name"></span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>

import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

export default {
    name: 'navigation-main',
    data () {
        return {
            data: this.$root.app.components.navigation.top
        }
    },
    methods: {
        scroll(e) {
            if (!['/ru/', '/en/', '/'].includes(window.location.pathname)) return
            e.preventDefault();
            let targetBlock = e.currentTarget.getAttribute('href').replace('/ru/', '').replace('/en/', '').replace('/', '').slice(1)
            if (!(targetBlock && document.querySelector(targetBlock))) return
            gsap.to(document.querySelector('html'), {duration: 0.6, scrollTo: document.querySelector(targetBlock).getBoundingClientRect().top}) //потому что scrollIntoView не пашет нормально
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
