<template>
    <component
        :href="link"
        :class="classObject"
        @click="$emit('click')"
        :is="link ? 'a' : 'div'"
        :target="buildTarget(link)"
    >
        <div class="z-card__content">
            <slot />
        </div>
    </component>
</template>

<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
    link: String,
    fullHeight: Boolean,
    external: Boolean
})

const buildTarget = (href) => {
    if (props.external) {
        return props.external
    }
    return /^http|^\/\//.test(href) ? '_blank' : null

}

const classObject = computed(() => {
    return [
        'z-card',
        {
            'z-card--link': props.link,
            'z-card--full-height': props.fullHeight
        }
    ]
})
</script>

<style lang="scss">
.z-card {
    $parent: &;
    @extend %card-decor;

    position: relative;
    background-color: $token-colors-white;

    &__content {
        padding: $token-spacers-l;
        z-index: 2;
        position: relative;
        color: $token-colors-black-90 !important;

        @include breakpoint(v-tablet) {
            padding: $token-spacers-s $token-spacers-2-xs;
        }
    }

    &--link {
        display: block;
        transition: box-shadow $transition;

        &:hover {
            box-shadow: 0 8px 16px $token-colors-black-30;
        }
    }

    &--full-height {
        height: 100%;
    }
}
</style>
