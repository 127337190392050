<template>
    <div
        class="z-tabs"
        :class="classObject"
    >
        <div class="z-tabs__container">
            <div class="z-tabs__header">
                <slot name="label"/>
            </div>
        </div>
        <slot name="content" />
    </div>
</template>

<script setup>
import {provide, ref, computed, inject, onMounted} from 'vue'

const props = defineProps({
    nested: Boolean,
    theme: {
        type: String,
        default: 'light'
    },
    size: {
        type: String,
        default: 'm',
        validator: prop => ['m', 's'].includes(prop)
    },
    initialId: {
        type: String,
        required: true
    }
})

// state
const openedTab = ref(`tabs-${props.initialId}`)

const isMounted = ref(false)

// inject
let handler = null
if (props.nested) {
    handler = inject('tabs-nested')
}

// computed
const classObject = computed(() => {
    return [`z-tabs--size-${props.size}`, `z-tabs--theme-${props.theme}`]
})

// methods
function selectTab (id) {
    openedTab.value = id
    if (props.nested && handler !== null && isMounted.value !== true) {
        handler()
    }
}

// provide
provide('tabs-provider', {
    openedTab,
    selectTab
})

// mounted
onMounted(() => {
    isMounted.value = true
})
</script>

<style lang="scss" src="./index.scss"></style>
