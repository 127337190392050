<template>
    <div class="products-team bg-15-bg u-top-padding--4xl u-bottom-padding--4xl">
        <div class="container">
            <div class="row">
                <div class="col-default-10 col-default-offset-1 col-tablet-12 col-tablet-offset-0">
                    <z-caption tag="h2" size="4xl" class="u-bottom-margin--l-important" weight="700">Направления</z-caption>
                    <p>М.Тех разрабатывает решения в&nbsp;нескольких направлениях, обеспечивающих качественный сервис во&nbsp;всех точках контакта с&nbsp;покупателями, развивают решения для работы с&nbsp;партнёрами, управления товарными запасами, выстраивания корпоративной культуры, повышения эффективности, бесперебойности розничных и&nbsp;операционных процессов</p>
                </div>
                <div class="col-default-10 col-default-offset-1 col-tablet-12 col-tablet-offset-0">
                    <z-accordion class="products-team__accordion u-top-margin--2xl">
                        <z-accordion-item id="client">
                            <template #custom-icon>
                                <z-icon name="1" />
                            </template>
                            <template #header>
                                Клиентский сервис
                            </template>
                            <template #body>
                                <p>Команда фокусируется на&nbsp;совершенствовании клиентских сервисов и&nbsp;реализации для покупателей
                                    удобного бесшовного пути, начиная от&nbsp;процесса выбора товара, до&nbsp;завершения заказа
                                    и&nbsp;быстрого получения покупки. Важным направлением является предоставление клиентам наиболее
                                    выгодных и&nbsp;персонализированных предложений, что возможно благодаря развитию бонусных программ,
                                    прогнозным моделям и&nbsp;мобильной платформе.</p>
                                <p>Этап оплаты покупки&nbsp;&mdash; также одна из&nbsp;важнейших составляющих покупательского пути, поэтому
                                    финтех-направление развивает новейшие инструменты оплаты покупок и&nbsp;собственную кредитную платформу
                                    на&nbsp;базе двенадцати банков-партнеров. Технологическая поддержка процессов ведется не&nbsp;только
                                    в&nbsp;рамках продаж и&nbsp;сервисного обслуживания конечных потребителей, но&nbsp;и&nbsp;юридических
                                    лиц, в&nbsp;рамках активно растущего b2b-направления.</p>
                            </template>
                        </z-accordion-item>
                        <z-accordion-item id="bussines">
                            <template #custom-icon>
                                <z-icon name="2" />
                            </template>
                            <template #header>
                                Решения для бизнеса
                            </template>
                            <template #body>
                                <p>Поддержка и&nbsp;развитие цифровых сервисов для розничного бизнеса, связанных с&nbsp;максимально
                                    эффективным движением и&nbsp;управлением товарными запасами, формированием ассортимента, ценовыми
                                    условиям, промо, взаимодействием и&nbsp;интеграцией с&nbsp;партнерами, включая прямые поставки
                                    и&nbsp;работу через маркетплейс.</p>
                            </template>
                        </z-accordion-item>
                        <z-accordion-item id="it-system">
                            <template #custom-icon>
                                <z-icon name="3" />
                            </template>
                            <template #header>
                                Внутренние сервисы <br>и корпоративные ИТ-системы
                            </template>
                            <template #body>
                                <p>Сервисы S2P, инструменты высокоуровневого моделирования и&nbsp;автоматизация прогнозирования денежных
                                    потоков, а&nbsp;также увеличение эффективности процессов, поддерживающих розничные операции. Компания
                                    также создает продукты для совершенствования опыта взаимодействия ритейлера с&nbsp;партнерами
                                    за&nbsp;счет удобных и&nbsp;автоматизированных инструментов для быстрого и&nbsp;простого ведения
                                    бизнеса, эффективного планирования и&nbsp;получения аналитики.</p>
                            </template>
                        </z-accordion-item>
                        <z-accordion-item id="infrastructure">
                            <template #custom-icon>
                                <z-icon name="4" />
                            </template>
                            <template #header>
                                Инфраструктура
                            </template>
                            <template #body>
                                <p>Компания развивает технологию разработки микросервисов и&nbsp;middleware, DevOps, SRE, FinOps
                                    и&nbsp;SecOps практики, совершенствует использование облачной инфраструктуры, в&nbsp;рамках собственной
                                    инфраструктуры поддерживает и&nbsp;развивает как физическую составляющую, так и&nbsp;сетевые каналы,
                                    и&nbsp;оборудование.</p>
                            </template>
                        </z-accordion-item>
                        <z-accordion-item id="big-data">
                            <template #custom-icon>
                                <z-icon name="5" />
                            </template>
                            <template #header>
                                Big Data
                            </template>
                            <template #body>
                                <p>Одним из&nbsp;ключевых направлений в&nbsp;рамках цифровой трансформации ритейла является
                                    data-ориентированный подход ко&nbsp;всем ключевым бизнес-процессам. Для этого компания создала
                                    и&nbsp;развивает собственный Дата-офис, который разрабатывает решения для ритейла на&nbsp;основе
                                    аналитики данных и&nbsp;алгоритмов машинного обучения (ML) в&nbsp;ряде направлений, включая формирование
                                    персонализированных предложений и&nbsp;коммуникаций</p>
                            </template>
                        </z-accordion-item>
                        <z-accordion-item id="security">
                            <template #custom-icon>
                                <z-icon name="6" />
                            </template>
                            <template #header>
                                Информационная безопасность
                            </template>
                            <template #body>
                                <p>Решающую роль в&nbsp;защите ценных активов компании играет информационная безопасность. Поддержка сервисов и&nbsp;инструментов по&nbsp;ключевым аспектам информационной безопасности является неотъемлемой частью стратегии компании в&nbsp;современном цифровом ландшафте. Снижение рисков, защита&nbsp;КИ, обеспечение нормативных требований, поддержка непрерывности бизнеса, безопасное сотрудничество&nbsp;— основа долгосрочного успеха и&nbsp;репутации.</p>
                            </template>
                        </z-accordion-item>
                    </z-accordion>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.products-team {
    position: relative;

    &__accordion {
        border-top: 2px solid $token-colors-bg-20;
    }

    &:before {
        content: '';
        background-image: url('/images/mainpage/bg-teams.svg');
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: 135% auto;
        background-repeat: repeat-x;
        background-position: 0 0;
        max-height: 640px;
        pointer-events: none;

        @media screen and (min-width: 1921px) {
            background-size: 2600px auto;
        }
    }
}
</style>
