<template>
    <div
        class="navigation-top"
        role="navigation"
        aria-label="Top navigation"
        :class="{'navigation-top--index': isIndex}"
    >
        <ul class="navigation-top__list">
            <li
                class="navigation-top__item"
                v-for="(item, index) in data"
                :key="index"
                :class="{'is-active': item.active}"
            >
                <z-link
                    :href="item.link"
                    :title="item.name"
                    size="s"
                    v-html="item.name"
                    :underlined="false"
                    theme="dark"
                ></z-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'navigation-top',
    props: {
        isIndex: Boolean
    },
    data () {
        return {
            data: this.$root.app.components.navigation.header
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
