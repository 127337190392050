<template>
    <component
        :is="tag"
        class="z-list"
        :class="[
            `z-list--${tag}`,
            `z-list--size-${size}`,
            { 'z-list--bordered': bordered },
            { 'z-list--reset-counter': resetCounter },
            { [`z-list--theme-${theme}`]: !icon && tag === 'ul' }
        ]"
    >   
        <slot />
    </component>
</template>

<script setup>
import { provide } from 'vue'

const props = defineProps({
    size: {
        type: String,
        default: 'l',
        validator: prop => ['l', 'm'].includes(prop)
    },
    tag: {
        type: String,
        default: 'ul',
        validator: prop => ['ul', 'ol'].includes(prop)
    },
    bordered: {
        type: Boolean,
        default: false
    },
    resetCounter: { // обнуляет счетчик (нужно для вложенных списков)
        type: Boolean,
        default: false
    },
    icon: {
        type: String
    },
    theme: {
        type: String,
        default: 'square',
        validator: prop => ['dash', 'dot', 'square'].includes(prop)
    }
})

provide('iconName', props.icon)
provide('tag', props.tag)
provide('size', props.size)
</script>

<style lang="scss" src="./index.scss"></style>
