<template>
    <div class="z-checkbox-group" v-if="data.length">
        <div class="z-checkbox-group__list">
            <div
                class="z-checkbox-group__item"
                v-for="(item, index) in data"
                :key="index"
            >
                <z-checkbox
                    :data="item"
                    :size="size"
                    :key="`${item.id}-${item.selected}`"
                    @change="onChange($event, item)"
                />
            </div>
        </div>
        <span
            :class="['z-checkbox-group__error', errorClass]"
            v-if="error"
            v-html="error"
        ></span>
    </div>
</template>

<script setup>
import { ref, computed, onBeforeMount } from 'vue'
import { localize } from '@/utils/i18n'

const props = defineProps({
    data: {
        type: Array
    },
    modelValue: {
        type: Array
    },
    required: {
        type: Boolean,
        default: false
    },
    errorClass: {
        type: String
    },
    size: {
        type: String,
        default: 'm',
        validator: prop => ['s', 'm', 'l'].includes(prop)
    }
})

const emit = defineEmits([
    'update:modelValue',
    'change'
])

// static data
const errorText = localize({
    ru: 'Поле обязательно для заполнения',
    en: 'Required field',
    cn: '填项目'
})

// refs
let localValue = ref([])
let error = ref('')

// computed
const selected = computed({
    get() {
        if (props.modelValue !== undefined) return props.modelValue // для работы без v-model
        return localValue.value
    },
    set(value) {
        localValue.value = value
        emit('update:modelValue', value)
    }
})

// methods
const validate = () => {
    if (props.required) {
        if (!(selected.value && selected.value.length)) {
            error.value = errorText
            return
        }
    }
    error.value = ''
}

// events
const onChange = (state, item) => {
    const index = selected.value.indexOf(item.id)
    if (!state.value && index !== -1) {
        localValue.value.splice(index, 1)
    } else {
        localValue.value.push(item.id)
    }

    emit('update:modelValue', localValue.value)
    emit('change', Array.from(selected.value))
    validate()
}

onBeforeMount (() => {
    props.data.forEach(item => {
        if (item.selected) {
            localValue.value.push(item.id)
        }
    })
    emit('update:modelValue', localValue.value)
})

defineExpose({
    validate
})
</script>

<style lang="scss">
.z-checkbox-group {
    &__list {
        display: flex;
        gap: 24px;
    }

    &__error {
        font-size: var(--errorTextSize);
        color: var(--errorTextColor);
        position: relative;
    }
}
</style>