<script>
import {compile, h} from 'vue'
export default {
    name: 'vue-raw',
    props: {
        raw: HTMLElement | String
    },
    render () {
        const div = document.createElement('div')
        div.innerHTML = this.raw
        return h(compile(div.outerHTML.replace(/\s{2,}/g, '')))
    }
}
</script>
