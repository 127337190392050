import {localize} from '@/utils/i18n'

export default {
    text: {
        title: localize({
            ru: 'Использование файлов cookie',
            en: 'Use of&nbsp;cookies'
        }),
        description: localize({
            ru: 'Этот веб-сайт использует файлы cookie. Некоторые обеспечивают работу сайта, а&nbsp;другие предназначены для повышения удобства пользователей. Нажмите &laquo;Согласен&raquo;, чтобы принять наши файлы cookie.',
            en: 'This website uses cookies. Some make the site work, while others are designed to&nbsp;improve the user experience. Click \'I agree\' to&nbsp;accept our cookies. You can change your cookie settings by&nbsp;clicking &laquo;Change Settings&raquo; or&nbsp;on&nbsp;the <z-link href="/policy">\'Cookie Policy\'</z-link> page. Screen reader support included.'
        }),
        button: {
            agree: localize({
                ru: 'Согласен',
                en: 'I agree'
            }),
            more: localize({
                ru: 'Изменить настройки',
                en: 'Change settings'
            })
        }
    },
    settings: [
        {
            title: localize({
                ru: 'Обязательные',
                en: 'Mandatory'
            }),
            id: 'mandatory',
            locked: true,
            checked: true,
            description: localize({
                ru: 'Эти файлы необходимы для правильной работы сайта и&nbsp;функционала.',
                en: 'These files are necessary for the website to&nbsp;function properly.'
            })
        },
        {
            title: localize({
                ru: 'Эксплуатационные',
                en: 'Operational'
            }),
            id: 'operational',
            locked: false,
            checked: false,
            description: localize({
                ru: 'Эти файлы собирают информацию о&nbsp;том, как вы&nbsp;используете сайт, и&nbsp;помогают нам улучшать его в&nbsp;соответствии с&nbsp;вашими потребностями.',
                en: 'These cookies collect information about how you use the site and help&nbsp;us improve it&nbsp;to&nbsp;suit your needs.'
            })
        },
        {
            title: localize({
                ru: 'Функциональные',
                en: 'Functional'
            }),
            id: 'functional',
            locked: false,
            checked: false,
            description: localize({
                ru: 'Эти файлы позволяют повысить удобство использования сайта, сохраняя информацию о&nbsp;настройках пользователя.',
                en: 'These files improve the usability of&nbsp;the site by&nbsp;storing information about the user&rsquo;s settings.'
            })
        },
        {
            title: localize({
                ru: 'Маркетинговые',
                en: 'Marketing'
            }),
            id: 'marketing',
            locked: false,
            checked: false,
            description: localize({
                ru: 'Эти файлы используются для показа рекламной информации, которая может быть вам интересна, в&nbsp;частности, на&nbsp;основе посещенных вами сайтов.',
                en: 'These cookies are used to&nbsp;display promotional information that may be&nbsp;of&nbsp;interest to&nbsp;you, in&nbsp;particular based on&nbsp;the websites you visit.'
            })
        }
    ]
}
