import {computed} from 'vue';
import locales from '../locales';

export const useCookieSettings = () => computed((operational, functional, marketing) => {
    return locales.settings.map((item) => {
        switch (item.id) {
        case ('operational'): {
            return {
                ...item,
                checked: operational
            }
        }
        case ('functional'): {
            return {
                ...item,
                checked: functional
            }
        }
        case ('marketing'): {
            return {
                ...item,
                checked: marketing
            }
        }
        default: {
            return item
        }
        }
    })
})
