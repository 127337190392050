<template>
    <div class="z-not-found">
        <div class="z-not-found__image" v-if="!hideImage">
            <template v-if="!!this.$slots.image">
                <slot name="image"></slot>
            </template>
            <z-image v-else :src="image" width="120" height="120"></z-image>
        </div>
        <div class="z-not-found__description">
            <template v-if="!!this.$slots.description">
                <slot name="description" v-if="!!this.$slots.description"></slot>
            </template>
            <template v-else>
                <div class="z-not-found__title" v-html="text.title"></div>
                <p class="z-not-found__text" v-html="text.description"></p>
            </template>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
export default {
    name: 'z-not-found',
    props: {
        hideImage: Boolean
    },
    data () {
        return {
            image: '/images/not-found/not-found.png',
            text: {
                title: localize({
                    ru: 'Ничего не найдено',
                    en: 'No results found'
                }),
                description: localize({
                    ru: 'По&nbsp;Вашему запросу ничего не&nbsp;нашлось.',
                    en: 'Sorry, no matches were found for your request. Try changing your search terms.'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.z-not-found {
    display: flex;
    align-items: center;

    @include breakpoint (mobile) {
        flex-direction: column;
        align-items: flex-start;
    }

    &__image {
        flex-shrink: 0;
        margin-right: $token-spacers-2-xs;

        @include breakpoint (mobile) {
            margin-right: 0;
            margin-bottom: $token-spacers-xs;
        }
    }

    &__title {
        display: block;
        margin-bottom: $token-spacers-xs;
        color: $token-colors-black;
        font-weight: 600;
        @include typo-level(XL);
        line-height: 1.4;
    }

    &__text {
        @include typo-level(S);
        line-height: 1.4;
    }

}
</style>
