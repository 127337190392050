<template>
    <div class="z-page-nav" :class="{ 'z-page-nav--with-back-link': data['back-link'] }">
        <z-link
            :href="data.prev ? data.prev.url : 'javascript:void(0)'"
            :class="{ 'is-disabled': !(data.prev && data.prev.url) }"
            :icon="arrow"
            icon-position="left"
            inline-flex
            :underlined="false"
            dir="left"
            size="s"
        >
            <span class="hide-me--mobile" v-html="prevText"/>
        </z-link>
        <div class="z-page-nav__button-wrapper">
            <z-button
                v-if="data['back-link']"
                size="m"
                kind="bordered"
                tag="a"
                :href="data['back-link']"
                v-html="buttonText"
            ></z-button>
        </div>
        <z-link
            :href="data.next ? data.next.url : 'javascript:void(0)'"
            :class="{ 'is-disabled': !(data.next && data.next.url) }"
            :icon="arrow"
            icon-position="right"
            :underlined="false"
            dir="right"
            size="s"
        >
            <span class="hide-me--mobile" v-html="nextText"/>
        </z-link>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'z-page-nav',
    props: {
        data: {
            required: true,
            type: Object
        },
        buttonText: {
            type: String,
            default: 'К списку'
        },
        isDetailPage: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            arrow: 'arrows/caret',
            defaultText: {
                prev: localize({
                    ru: 'Предыдущая',
                    en: 'Preview'
                }),
                next: localize({
                    ru: 'Следующая',
                    en: 'Next'
                })
            }
        }
    },
    computed: {
        nextText () {
            if (this.isDetailPage) {
                return this.defaultText.next
            }
            return this.data.next?.name || this.defaultText.next
        },
        prevText () {
            if (this.isDetailPage) {
                return this.defaultText.prev
            }
            return this.data.prev?.name || this.defaultText.prev
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
