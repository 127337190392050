<template>
    <div class="z-filelist-item" :class="buildClass">
        <div class="z-filelist-item__body">
            <div class="z-filelist-item__icon" v-if="icon || this.$slots['icon']">
                <z-icon v-if="icon" :name="icon" :width="size === 'l' ? '48' : '72'" :height="size === 'l' ? '48' : '72'"/>
                <slot v-else name="icon"/>
            </div>
            <div class="z-filelist-item__slot">
                <div class="z-filelist-item__wrapper" v-if="lang || date">
                    <span v-if="lang" class="z-filelist-item__text" v-html="lang"/>
                    <span v-if="date" class="z-filelist-item__text">{{ formattedDate }}</span>
                </div>
                <slot/>
                <div class="z-filelist-item__wrapper z-filelist-item__wrapper--bottom" v-if="fileType || fileSize">
                    <span v-if="fileType" v-html="fileType" class="z-filelist-item__text"/>
                    <span v-if="fileSize" v-html="fileSize" class="z-filelist-item__text"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {momentFilter} from '@/utils/filter'
export default {
    name: 'z-filelist-item',
    props: {
        size: {
            type: String,
            validator: prop => ['l', 'xl'].includes(prop),
            default: 'xl'
        },
        icon: {
            type: String,
            default: ''
        },
        lang: {
            type: String
        },
        fileType: {
            type: String
        },
        fileSize: {
            type: String,
            default: ''
        },
        date: {
            type: [String, Number],
            default: ''
        },
        iconTop: {
            type: Boolean
        },
        centered: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        formattedDate () {
            return typeof (this.date) === 'number' ? `${momentFilter(this.date, 'DD MMMM YYYY')} года` : this.date
        },

        buildClass () {
            return [
                {'z-filelist-item--with-icon': this.icon || this.$slots['icon']},
                {'z-filelist-item--icon-top': this.iconTop},
                {'z-filelist-item--centered': this.centered}
            ]
        }
    }
}
</script>
