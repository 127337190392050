<template>
    <section class="mainpage-about">
        <div class="container">
            <div class="row">
                <div class="col-default-10 col-default-offset-1 col-desktop-12 col-desktop-offset-0">
                    <z-caption tag="h2" size="4xl" class="mainpage-about__title" weight="700">
                        <span class="mainpage-about__title-helper">Цифровые технологии</span>
                        для эффективного бизнеса и лучшего клиентского опыта
                    </z-caption>
                    <p class="mainpage-about__description">М.Тех&nbsp;&mdash; разработчик ИТ-решений и&nbsp;digital-партнер Группы М.Видео-Эльдорадо, ведущей российской компании в&nbsp;сфере потребительской электроники. М.Тех фокусируется на&nbsp;цифровизации и&nbsp;автоматизации ритейла, а&nbsp;также развитии финтех-сервисов. Стек технологий объединяет лучшее в&nbsp;онлайне и&nbsp;магазинах для бесшовного клиентского опыта, поддержки и&nbsp;развития бизнеса.</p>
                    <div class="wide-block">
                        <img src="/images/mainpage/cube--v-tablet.svg" class="mainpage-about__image hide-me--mobile show-me--v-tablet">
                    </div>
                    <p class="mainpage-about__description mainpage-about__description--absolute">ООО &laquo;М&nbsp;Тех&raquo; входит в&nbsp;<z-link external target="_blank" href="https://www.gosuslugi.ru/itorgs">список</z-link> аккредитованных ИТ-компаний Министерства связи, цифрового развития и&nbsp;массовых коммуникаций Российской Федерации, ИНН&nbsp;7723809533</p>
                </div>
            </div>
        </div>
    </section>
</template>
<script setup>

</script>
<style lang="scss">
.mainpage-about {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), $token-colors-bg-15;
    padding-top: 192px;
    padding-bottom: 120px;
    position: relative;
    overflow: hidden;

    @media screen and (min-width: 1921px) {
        min-height: 1024px;
    }

    @include breakpoint (tablet) {
        padding-top: 0;
        padding-bottom: 80px;
        min-height: auto;
    }

    @include breakpoint (v-tablet) {
        padding-top: 153px; //высота хедера
        padding-bottom: 32px;
    }

    @include breakpoint (mobile) {
        padding-top: 115px; //высота хедера
        background-size: 146%;
    }

    &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('/images/mainpage/bg-about.svg');
        background-size: 135% auto;
        background-repeat: repeat-x;
        background-position: 0 0;
        pointer-events: none;

        @media screen and (min-width: 1921px) {
            background-size: 2600px auto;
            background-position: 1vw 0;
        }

        @include breakpoint (laptop) {
            background-position: 1% 0;
        }

        @include breakpoint (mobile) {
            background-size: 146%;
            background-position: 15% 0;
        }
    }


    > .container {
        position: relative;
        z-index: 3;

        &:after {
            content: '';
            background-image: url('/images/mainpage/cube.svg');
            background-size: 1311px 600px;
            background-repeat: no-repeat;
            background-position: 98% 70%;
            display: block;
            width: 100%;
            height: 100%;
            z-index: -1;
            position: absolute;
            top: 0;
            max-height: 969px;
            pointer-events: none;

            @include breakpoint (laptop) {
                background-size: 983px 591px;
                background-position: 125% 60%;
            }

            @include breakpoint (tablet) {
                // background-position: 194% 69%;
                background-position: calc(100% + 351px) 69%;
            }

            @include breakpoint (v-tablet) {

                display: none;
            }
        }
    }

    &__title {
        max-width: 743px;
        line-height: 1.2;
        margin-top: 100px;
        font-family: $secondaryFont;

        @include breakpoint (tablet) {
            margin-top: 270px;
        }

        @include breakpoint (v-tablet) {
            margin-top: 0;
            max-width: 100%;
        }

        &-helper {
            display: block;
            width: 100%;

            @include breakpoint (mobile) {
                width: auto;
                display: inline-block;
            }
        }
    }

    &__description {
        @include margin-level (top, 2XL);
        font-weight: 500;
        font-size: 24px;
        max-width: 620px;
        position: relative;

        @include breakpoint (v-tablet) {
            margin-bottom: 55px;
            max-width: 100%;
        }

        @include breakpoint (mobile) {
            font-size: 18px;
            margin-top: 20px;
            margin-bottom: 16px;
        }

        &:before {
            content: '';
            position: absolute;
            width: 16px;
            height: 16px;
            background-color: $token-colors-primary;
            top: -21px; //высота и отступ
            left: -21px; //ширина и отступ

            @include breakpoint (v-tablet) {
                display: none;
            }
        }

        &--absolute {
            position: absolute;
            right: 135px;
            // right: 40px;
            bottom: -50px;
            // bottom: -18px;
            font-size: 14px;
            max-width: 345px;
            margin-top: 0;
            font-weight: 400;

            @media screen and (min-width: 1920px) {
                right: 150px;
                bottom: -50px;
            }

            @include breakpoint (tablet) {
                max-width: 753px;
                position: relative;
                right: 0;
                bottom: 0;
                margin-top: 58px;
            }

            @include breakpoint (v-tablet) {
                max-width: 100%;

                &:before {
                    position: relative;
                    display: block;
                    top: -8px;
                    left: 0;
                    flex-shrink: 0;
                    margin-right: 8px;
                }
            }

            @include breakpoint (mobile) {
                margin-top: 0;
            }
        }
    }

    .wide-block {
        margin-left: -$token-grids-v-tablet-offset;
        margin-right: -$token-grids-v-tablet-offset;

        @include breakpoint (mobile) {
            margin-left: -$token-grids-mobile-offset;
            margin-right: -$token-grids-mobile-offset;
            background-image: url('/images/mainpage/cube.svg');
            background-size: 170% 100%;
            background-repeat: no-repeat;
            background-position: 67% 50%;
            min-height: 350px;
        }

        @media screen and (min-width: calc($breakpoint-v-tablet + 1px)) {
            display: none;
        }

        .mainpage-about__image {
            display: block;
            width: 100%;
        }
    }

}
</style>
