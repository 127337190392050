<template>
    <div class="news-results" v-if="data && data.length">
        <a
            class="news-results__item news-item"
            :href="item.detailPageUrl"
            v-for="(item, index) in data"
            :key="index"
        >
            <div
                class="news-item__image"
                :style="`background-image: url(${item.previewPicture.link})`"
            ></div>

            <div class="news-item__container">
                <div class="news-item__date">
                    {{ formatDate(item.activeFrom) }}
                </div>
                <p class="news-item__title">
                    <span v-html="item.name"></span>
                </p>
                <p class="news-item__detail" v-if="item.previewText" v-html="item.previewText"></p>
                <div
                    class="news-item__tags"
                    v-if="(item.subject && item.subject.length) || (item.type && item.type.value)"
                >
                    <span
                        class="news-item-tag"
                        v-if="item.type && item.type.value"
                        v-html="item.type.value"
                    ></span>
                    <span
                        class="news-item-tag"
                        v-for="(item, index) in item.subject"
                        :key="index"
                        v-html="item.value"
                    ></span>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import { momentFilter } from '@/utils/filter'

export default {
    name: 'news-results',
    props: {
        data: Array
    },
    methods: {
        formatDate (date) {
            return typeof (date) === 'number' ? `${momentFilter(date, 'DD MMMM YYYY')}` : date
        }
    }
    
}
</script>

<style lang="scss">
.news-item {
    text-decoration: none;
    color: $token-colors-black;
    display: flex;

    &:hover {
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    }

    & + & {
        margin-top: 32px;
    }

    &__date {
        color: $token-colors-black-60;
        margin-bottom: 16px;
        @include typo-level(S);
    }

    &__name {
        @include typo-level(L);
    }

    &__container {
        flex-grow: 1;
        padding: 24px 32px;
    }

    &__image {
        width: 200px;
        height: 200px;
        flex-shrink: 0;
        background: $token-colors-black-20;
    }
}

.news-item-tag {
    @include typo-level(2XS);
    border: 1px solid $token-colors-primary;
    border-radius: 4px;
    padding: 4px;

    & + & {
        margin-left: 8px;
    }
}
</style>