<template>
    <section class="mainpage-vacancies" v-if="data && data.length">
        <div class="container">
            <div class="row">
                <div class="col-default-10 col-default-offset-1 col-v-tablet-12 col-v-tablet-offset-0">
                    <z-caption
                        tag="h2"
                        size="4xl"
                        weight="700"
                        class="white-text"
                    >
                        Стань частью команды <span class="primary-text">М.Тех</span>
                    </z-caption>
                </div>
                <div class="col-default-10 col-default-offset-1 col-mobile-12 col-mobile-offset-0">
                    <ul class="mainpage-vacancies__list">
                        <li class="mainpage-vacancies__item"
                            v-for="(vacancy, index) in data"
                            :key="index"
                        >
                            <a
                                class="mainpage-vacancies__link"
                                :href="vacancy.detailUrl"
                            >
                                <div class="mainpage-vacancies__info">
                                    <div class="mainpage-vacancies__name" v-html="vacancy.name"/>
                                    <div class="mainpage-vacancies__preview" v-html="vacancy.previewText"/>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { useGetter } from '@/composable/useGetter'

const { app } = useGetter(['app'])

const data = app.value?.components?.main?.vacancies
</script>

<style lang="scss">
.mainpage-vacancies {
    $parent: &;

    @include padding-level (top, 4XL);
    @include padding-level (bottom, 4XL);
    background-color: $token-colors-bg-20;

    &__list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 0 $token-spacers-l;

        @include breakpoint (v-tablet) {
            gap: 0;
            border-bottom: 1px solid $token-colors-black-20;
        }
    }

    &__item {
        width: calc(50% - $token-spacers-xs);
        padding: $token-spacers-xs 0;
        border-bottom: 1px solid $token-colors-black-20;

        &:nth-child(1), &:nth-child(2) {
            border-top: 1px solid $token-colors-black-20;
        }

        @include breakpoint (v-tablet) {
            width: 100%;
            border-top: 1px solid $token-colors-black-20;
            border-bottom: none;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        color: $token-colors-white;
        text-decoration: none;
        gap: $token-spacers-xl;

        &::after {
            content: '';
            display: block;
            width: 32px;
            height: 32px;
            flex-shrink: 0;
            background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_799_17872)'%3E%3Crect width='32' height='32' fill='%23FF4171'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 15.2428C7.44772 15.2428 7 15.6906 7 16.2428C7 16.7951 7.44772 17.2428 8 17.2428L20.919 17.2428L18.3836 19.7782C17.993 20.1688 17.993 20.8019 18.3836 21.1925C18.7741 21.583 19.4072 21.583 19.7978 21.1925L24.0296 16.9606C24.2169 16.7789 24.3333 16.5245 24.3333 16.2428C24.3333 16.005 24.2503 15.7865 24.1116 15.6149C24.0894 15.5874 24.0656 15.5609 24.0404 15.5356L19.7978 11.293C19.4072 10.9025 18.7741 10.9025 18.3836 11.293C17.993 11.6835 17.993 12.3167 18.3836 12.7072L20.9192 15.2428L8 15.2428Z' fill='%23FAFAFA'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_799_17872'%3E%3Crect width='32' height='32' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        }

        &:hover {
            color: $token-colors-white;

            #{$parent}__name {
                text-decoration: underline;
            }
        }

        @include breakpoint (v-tablet) {
            gap: $token-spacers-xs;
        }
    }

    &__name {
        font-family: $primaryFont;
        font-style: normal;
        font-weight: 700;
        @include typo-level(2XL);
        line-height: 120%;
        letter-spacing: -0.01em;
        text-decoration: none;
        margin-bottom: $token-spacers-3-xs;

        @include breakpoint(tablet) {
            @include typo-level(XL);
        }

        @include breakpoint (mobile) {
            gap: $token-spacers-xs;
        }
    }

    &__preview {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        @include typo-level(M);
    }
}
</style>
