<template>
    <div class="committees" v-if="data">

        <template v-if="data.table">
            <z-table
                class="committees__table u-bottom-margin--2xl"
                :columns="tableColumns"
            >
                <z-table-row
                    v-for="(row, rowIndex) in data.table"
                    :key="rowIndex"
                >
                    <z-table-col index="0">
                        <z-link :href="row.detailPageUrl">
                            <span v-html="row.name"></span>
                        </z-link>
                    </z-table-col>
                    <z-table-col index="1">
                        <span v-if="row.role && row.role.value" v-html="row.role.value"></span>
                    </z-table-col>
                    <z-table-col
                        :index="index + 2"
                        v-for="(item, index) in row.committees"
                        :key="index"
                    >
                        <!-- <z-icon
                            v-if="item.role"
                            :name="item.role.toLowerCase()"
                            width="24"
                            height="24"
                        /> -->
                        <span v-if="item.role" v-html="item.role"></span>
                        <template v-else> - </template>
                    </z-table-col>
                </z-table-row>
            </z-table>
        </template>

        <div class="committees__list" v-if="data.items && data.items.length">
            <template  v-for="(item, index) in data.items" :key="index">
                <div class="committees__item" v-if="item.detailText">
                    <z-caption class="u-bottom-margin--xs-important" weight="700" tag="h2" size="xl">
                        <span v-html="item.name"></span>
                    </z-caption>
                    <vue-raw :raw="item.detailText"></vue-raw>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'committees',
    data () {
        return {
            data: this.$root.app.components.committees
        }
    },
    computed: {
        tableColumns () {
            if (!this.data.table) return []

            let columns = ['Name', 'Status']
            if (this.data.table[0]?.committees) {
                const committeesNames = this.data.table[0]?.committees.map(item => item.name)
                columns = columns.concat(committeesNames)
            }

            return columns
        }
    }
}
</script>

<style lang="scss">
.committees {
    &__item {
        & + & {
            margin-top: 32px;
        }
    }

    &__table {
        th:nth-of-type(1),
        th:nth-of-type(2),
        td:nth-of-type(1),
        td:nth-of-type(2) {
            width: 20%;
            min-width: 100px;
        }

        th,
        td {
            width: 10%;
            text-align: left;
        }
    }
}
</style>