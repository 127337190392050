<template>
    <div class="slider" :class="{ 'slider--nav-outside': navOutside }">
        <div class="slider__wrapper">
            <button class="slider__nav-btn slider__nav-btn--prev" ref="prev">
                <z-icon
                    name="arrows/caret-slider"
                />
            </button>
            <div class="swiper-container slider__container" ref="sliderRef">
                <div class="swiper-wrapper">
                    <slot></slot>
                </div>
            </div>

            <button class="slider__nav-btn slider__nav-btn--next" ref="next">
                <z-icon
                    name="arrows/caret-slider"
                />
            </button>
        </div>
        <!-- <div class="slider__controls">
        </div> -->
        <div class="swiper-pagination"></div>
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import Swiper from 'swiper/dist/js/swiper.js'

const next = ref(null)
const prev = ref(null)
const slider = ref(null)
const sliderRef = ref(null)

const props = defineProps({
    swiperOptions: {
        type: Object
    },
    effect: {
        type: String,
        default: 'slide'
    },
    slidesPerView: {
        type: [String, Number],
        default: 1
    },
    spaceBetween: {
        type: [String, Number],
        default: 48
    },
    navOutside: {
        type: Boolean,
        default: false
    },
    loop: {
        type: Boolean,
        default: false
    }
})

const init = () => {
    let options = {
        observer: true,
        loop: props.loop,
        observeParents: true,
        speed: 1000,
        navigation: {
            prevEl: prev.value,
            nextEl: next.value
        },
        slidesPerView: props.slidesPerView,
        spaceBetween: props.spaceBetween,
        effect: props.effect,
        on: {
            slideChange() {
            }
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        }
    }

    if (props.slidesPerView !== 1) {
        options.breakpoints = {
            1679: {
                slidesPerView: 2,
                spaceBetween: 32
            },
            1023: {
                slidesPerView: 1,
            }
        }
    }

    slider.value = new Swiper(sliderRef.value, options)
}

onMounted(() => {
    init()
})
</script>

<style lang="scss">
.slider {
    $parent: &;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    @include breakpoint(mobile) {
        width: auto;
    }

    &__wrapper {
        width: 100%;
        display: flex;
    }

    img {
        display: block;
        width: 100%;
    }

    &__container {
        border-radius: 5px;
    }

    &__controls {
        display: flex;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
        width: 100%;
        justify-content: space-between;
        // padding: 0px $token-spacers-xs;

        @include breakpoint(v-tablet) {
            // padding: 0 $token-spacers-2-xs;
        }
    }

    &__nav-btn {
        border: none;
        pointer-events: all;
        background: none;
        padding: 0;
        transition: all $transition;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        .z-icon {
            width: 80px;
            height: 80px;

            path {
                fill: $token-colors-black-40;
            }

            @include breakpoint(v-tablet) {
                width: 42px;
                height: 42px;
            }
        }

        &--prev {
            margin-right: $token-spacers-xs;

            &:hover {
                transform: translateX(-2px);
            }

            .z-icon {
                transform: rotate(-90deg);
            }
        }

        &--next {
            margin-left: $token-spacers-xs;

            &:hover {
                transform: translateX(2px);
            }

            .z-icon {
                transform: rotate(90deg);
            }
        }

        &.swiper-button-disabled {
            cursor: auto;
            opacity: 0.3;

            &:hover {
                transform: translateX(0);
            }

            .z-icon {
                path {
                    fill: $token-colors-black-5;
                }
            }
        }
    }

    .swiper-slide {
        height: auto;
        border-radius: 5px;
        overflow: hidden;

        &__content,
        &__wrapper {
            height: 100%;
        }
    }

    .swiper-container {
        width: 100%;
    }

    .swiper-pagination {
        @include margin-level(top, S);
        width: 100%;
        position: relative;
    }

    .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        margin-right: 21px;
        transition: transform $transition;
        background: $token-colors-bg-20;
        opacity: 1;

        &-active {
            background: $token-colors-primary;
            transform: scale(2);
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &--nav-outside {
        .slider__wrapper {
            align-items: center;
        }

        .slider__nav-btn .z-icon {
            @include breakpoint (tablet) {
                width: 25px;
                height: 25px;
            }
        }

        .slider__nav-btn {
            position: absolute;
            z-index: 2;

            @include breakpoint (v-tablet) {
                position: relative;
            }
        }

        .slider__nav-btn--prev {
            left: -80px;

            @include breakpoint (tablet) {
                left: -48px;
            }

            @include breakpoint (v-tablet) {
                left: auto;
            }
        }

        .slider__nav-btn--next {
            right: -80px;

            @include breakpoint (tablet) {
                right: -48px;
            }

            @include breakpoint (v-tablet) {
                right: auto;
            }
        }
    }
}

</style>
