<template>
    <div
        class="site-selector"
        :class="{'site-selector--index': isIndex}"
    >
        <template v-for="item in data" :key="item.lid">
            <a
                class="site-selector__link"
                v-if="!item.active"
                v-html="item.lang"
                :title="item.title"
                :href="setUrl(item.url, item.lid)"
            ></a>
        </template>
    </div>
</template>

<script>
export default {
    name: 'site-selector',
    props: {
        isIndex: Boolean
    },
    data () {
        return {
            page: this.$root.app.page,
            data: this.$root.app.components['site-selector']
        }
    },
    methods: {
        setUrl (url, lid) {
            if (!('override_links' in this.page)) return url
            if (!(lid in this.page.override_links)) return url

            return this.page.override_links[lid]
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
