<template src="./index.html"></template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'z-logo',
    mixins: [mixinDevice],
    props: {
        isIndex: {
            type: Boolean
        },
        siteDir: {
            type: String,
            default: '/',
            required: true
        },
        theme: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            title: this.$root.lang === 'ru' ? 'Перейти на главную страницу' : 'Go to mainpage'
        }
    },
    computed: {
        getBurgerMenu () {
            return this.$store.getters.burgerMenu
        },
        buildSrc () {
            let src = '/images/logo/logo'

            // let isInvert = document.body.classList.contains('invert')

            // src += '--'
            // src += this.theme ? this.theme : ((isInvert && !this.getBurgerMenu) ? 'white' : 'red')
            // src += '--'
            // src += this.$root.lang

            if (this.size === 'sm') {
                src += '--size-sm'
            }

            return `${src}.png`
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
