<template>
    <figure class="z-figure">
        <slot
            name="top"
            :style="alignTextStyles"
        >
            <figcaption
                class="z-figure__caption"
                v-if="caption && isCaptionTop"
                v-html="caption"
                :style="alignTextStyles"
            ></figcaption>
        </slot>

        <div
            v-if="!video"
            :class="[
                'z-figure__image',
                {'z-figure--border-radius': borderRadius}
            ]"
            :style="backgroundStyles"
        ></div>

        <div
            :class="[
                {'z-figure__video' : video},
                {'z-figure--border-radius': borderRadius}
            ]"
            v-if="video"
        >
            <z-video
                :src="srcVideo"
                :poster="image"
                :autoplay="false"
            ></z-video>
        </div>

        <slot
            name="bottom"
            :style="alignTextStyles"
        >
            <figcaption
                class="z-figure__caption"
                v-if="caption && !isCaptionTop"
                v-html="caption"
                :style="alignTextStyles"
            ></figcaption>
        </slot>
    </figure>
</template>

<script>
export default {
    name: 'z-figure',
    props: {
        image: {
            type: String
        },
        caption: {
            type: String
        },
        captionPosition: {
            type: String,
            default: 'bottom',
            validator: prop => ['top', 'bottom'].includes(prop)
        },
        borderRadius: Boolean,
        video: {
            type: Boolean,
            default: false
        },
        srcVideo: [String, Array], // обязательный пропс для видео
        paddingTop: String,
        alignText: {
            type: String,
            validator: prop => ['left', 'right', 'center'].includes(prop)
        }
    },
    data () {
        return {
            defaultImage: require('./images/img.png')
        }
    },
    computed: {
        backgroundStyles () {
            return {
                'background-image': this.image ? `url(${this.image})` : `url(${this.defaultImage})`,
                'padding-top': this.paddingTop ? `${this.paddingTop}%` : null
            }
        },
        isCaptionTop () {
            return this.captionPosition === 'top'
        },
        alignTextStyles () {
            return { 'text-align': this.alignText ? `${this.alignText}` : null }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
