<template>
    <div class="layout" :class="`layout--${type}`">
        <div v-if="!!$slots['top']" class="layout__top">
            <slot name="top" />
        </div>
        <div
            class="layout__container"
            :class="{ 'container': type !== 'wide' }"
        >
            <div class="row u-entire-height">
                <div
                    class="layout__content"
                    :class="type === 'two-columns' ? 'col-default-9 col-tablet-12' : 'col-default-12'"
                >
                    <slot name="content" />
                </div>
                <aside class="layout__aside col-default-3 col-tablet-12" v-if="type === 'two-columns'">
                    <navigation-left />
                    <slot name="aside" />
                </aside>
            </div>
        </div>
    </div>
</template>

<script>
import NavigationLeft from '@/components/NavigationLeft'

export default {
    name: 'layout',
    props: {
        type: String,
        default: 'one-column',
        validator: prop => ['wide', 'one-column'].includes(prop)
    },
    components: {
        NavigationLeft
    }
}
</script>

<style lang="scss">
.layout {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    &__container {
        flex-grow: 1;
        overflow: hidden;
    }

    &__top {
        flex-shrink: 0;
        flex-grow: 0;
    }

    &__content,
    &__aside {
        margin-top: 0 !important;
        @include padding-level (bottom, 2XL);
        @include padding-level (top, 2XL);
    }

    &--wide {
        .layout__content {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
</style>
