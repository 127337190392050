<template>
    <section class="media-section">
        <div class="container">
            <div class="row">
                <div class="col-default-10 col-default-offset-1 col-tablet-12 col-tablet-offset-0" v-if="newsData && newsData.length">
                    <z-caption tag="h2" size="4xl" class="u-bottom-margin--m" weight="700">Новости</z-caption>
                </div>
                <div class="col-default-10 col-default-offset-1 col-mobile-12 col-mobile-offset-0" v-if="newsData && newsData.length">
                    <div class="media-section__news">
                        <news :news-data="newsData"></news>
                    </div>
                </div>
                <div class="media-section__blog" id="blog">
                    <div class="col-default-10 col-default-offset-1 col-tablet-12 col-tablet-offset-0" v-if="blogData && blogData.length">
                        <z-caption tag="h2" size="4xl" class="u-bottom-margin--m" weight="700">Блог</z-caption>
                    </div>
                    <div class="col-default-10 col-default-offset-1 col-desktop-12 col-desktop-offset-0 col-tablet-10 col-tablet-offset-1 col-mobile-12 col-mobile-offset-0" v-if="blogData && blogData.length">
                        <blog :blog-data="blogData"></blog>
                    </div>
                </div>
                <div class="media-section__awards">
                    <div class="col-default-10 col-default-offset-1 col-tablet-12 col-tablet-offset-0">
                        <z-caption tag="h2" size="4xl" class="u-bottom-margin--m" weight="700">Награды и премии</z-caption>
                    </div>
                    <div class="col-default-12">
                        <div class="row">
                            <div class="col-default-10 col-default-offset-1 col-desktop-12 col-desktop-offset-0">
                                <div class="awards">
                                    <div class="awards__list">
                                        <z-card class="awards-item">
                                            <img src="/images/mainpage/rbdigital2023.png" class="awards-item__image">
                                            <z-caption
                                                tag="h3"
                                                size="3xl"
                                                class="awards-item__title"
                                            >RB Digital Awards <span class="primary-text">2023</span>
                                            </z-caption>
                                            <div class="awards-item__description">«HR и внутренние коммуникации»</div>
                                        </z-card>
                                        <z-card class="awards-item">
                                            <img src="/images/mainpage/hrtech.png" class="awards-item__image">
                                            <z-caption
                                                tag="h3"
                                                size="3xl"
                                                class="awards-item__title"
                                            >HR Brand <span class="primary-text">2023</span>
                                            </z-caption>
                                            <div class="awards-item__description">«Цифровое лидерство»</div>
                                        </z-card>
                                        <z-card class="awards-item">
                                            <img src="/images/mainpage/award-2.svg" class="awards-item__image">
                                            <z-caption
                                                tag="h3"
                                                size="3xl"
                                                class="awards-item__title"
                                            >Retail Week Awards <span class="primary-text">2023</span>
                                            </z-caption>
                                            <div class="awards-item__description">«HR-решение года» и «IT-проект года»</div>
                                        </z-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { useGetter } from '@/composable/useGetter'

import News from './News.vue'
import Blog from './Blog.vue'

const { app } = useGetter(['app'])

const newsData = app.value.components.main.news
const blogData = app.value.components.main.blog

</script>

<style lang="scss">
.media-section {
    @include padding-level (top, 4XL);
    @include padding-level (bottom, 4XL);
    background-color: $token-colors-bg-15;
    overflow: hidden;

    > .container {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            background-image: url('/images/mainpage/cube--cutted.png');
            background-size: contain;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            max-width: 1973px;
            width: 100%;
            left: 100%;
            top: 8%;
            transform: translateX(-45%);
            padding-top: 105%;
            pointer-events: none;

            @include breakpoint (laptop) {
                transform: translateX(-55%);
                top: 5%;
            }

            @include breakpoint (tablet) {
                transform: translateX(-30%);
                width: 153%;
                padding-top: 186%;
                top: 15%;
            }

            @include breakpoint (v-tablet) {
                display: none;
            }
        }
    }

    &__news,
    &__blog {
        @include margin-level (bottom, 2XL);
    }

    &__blog,
    &__awards {
        @include padding-level (top, 2XL);
    }

    .awards__list {
        display: grid;
        gap: 48px;

        grid-template-columns: repeat(3, 1fr);

        @include breakpoint (laptop) {
            gap: 32px;
        }

        @include breakpoint (v-tablet) {
            gap: 16px;
        }

        @include breakpoint (mobile) {
            grid-template-columns: 1fr;
        }
    }

    .awards-item {
        text-align: center;
        min-height: 580px;

        @include breakpoint (tablet) {
            min-height: auto
        }

        &__title {
            @include typo-level (3XL);
            font-weight: 700;
            margin-bottom: $token-spacers-xs;
            margin-top: auto;
            padding-top: 60px;
        }

        &__image {
            max-width: 350px;

            @include breakpoint (tablet) {
                max-height: 100px;
            }

            @include breakpoint (mobile) {
                max-height: 77px;
            }
        }

        &__description {
            font-weight: 500;
            min-height: 56px;
            max-height: 56px;
            @include typo-level (L);

            @include breakpoint (v-tablet) {
                min-height: 75px;
                max-height: 75px;
            }

            @include breakpoint (mobile) {
                min-height: fit-content;
                max-height: none;
            }
        }

        .z-card__content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @include breakpoint (mobile) {
                padding-top: $token-spacers-m;
                padding-bottom: $token-spacers-xs;
            }
        }
    }
}
</style>
