import Velocity from 'velocity-animate';

export const useCookieAnimation = (duration) => {
    const appearAnimationBefore = (el) => {
        el.style.opacity = 0
    }

    const appearAnimationEnter = (el, done) => {
        Velocity(el, { opacity: 1 }, { duration: duration, delay: duration }, { complete: done })
    }

    const appearAnimationLeave = (el, done) => {
        Velocity(el, { opacity: 0 }, { duration: duration })
        Velocity(el, { display: 'none' }, { complete: done })
    }

    return {appearAnimationBefore, appearAnimationEnter, appearAnimationLeave}
}
