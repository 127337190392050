<template>
    <detail-page
        class="news-detail"
        v-if="data"
        :date="data.activeFrom"
        :detail="data.detailText"
        :nav="nav"
        :name="data.name"
    >
    </detail-page>
</template>

<script setup>
import {useGetter} from '../../composable/useGetter'

const { app } = useGetter(['app'])
const data = app.value.components['news-detail']?.item
const nav = app.value.components['news-detail']?.nav
</script>

<style lang="scss">
</style>
