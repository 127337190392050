<template>
    <div class="news">
        <slot name="title"></slot>
        <slider
            slides-per-view="3"
            nav-outside
        >
            <slide v-for="(item, index) in newsData" :key="index">
                <news-card
                    :link="item.detailPageUrl"
                    :date="item.activeFrom"
                    :title="getFormatedTitle(item.name)"
                    full-height
                    class="news-item"
                >
                </news-card>
            </slide>
        </slider>
    </div>
</template>

<script setup>
import { useGetter } from '@/composable/useGetter'

const { device } = useGetter(['device'])

defineProps({
    newsData: {
        type: Array,
        default: []
    }
})

const getFormatedTitle = (title) => {
    let maxLength = 110

    if (device.value === 'tablet') {
        maxLength = 130
    }

    if (device.value === 'mobile') {
        maxLength = 80
    }

    let trimmedString = title.substr(0, maxLength);

    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')))

    return title.length > maxLength ? `${trimmedString}...` : title
}

</script>

<style lang="scss">
.news {
    &-item {
        min-height: 450px;

        @include breakpoint (v-tablet) {
            min-height: 300px;
        }
    }

    &-card__title {
        @include breakpoint (mobile) {
            font-size: 18px;
        }
    }

    .swiper-pagination {
        display: none
    }

    .slider__container ,
    .slider .swiper-slide {
        border-radius: 0;
    }

    .slider__nav-btn--prev {
        margin-right: -$token-spacers-3-xs;

        @include breakpoint (v-tablet) {
            left: -50px;
            margin-right: 0;
        }

        @include breakpoint (mobile) {
            left: -$token-spacers-3-xs;
            margin-right: -$token-spacers-3-xs;
        }
    }

    .slider__nav-btn--next {
        margin-left: $token-spacers-3-xs;

        @include breakpoint (v-tablet) {
            right: -50px;
            margin-left: 0;
        }

        @include breakpoint (mobile) {
            margin-left: -$token-spacers-3-xs;
            right: -$token-spacers-3-xs;
        }
    }

    .slider--nav-outside .slider__nav-btn {
        @include breakpoint (v-tablet) {
            position: absolute;
        }
        @include breakpoint (mobile) {
            position: relative;
        }
    }

    .slider__nav-btn .z-icon {
        @include breakpoint (tablet) {
            width: $token-spacers-l;
            height: $token-spacers-l;
        }
        @include breakpoint (mobile) {
            width: $token-spacers-m;
            height: $token-spacers-m;
        }
    }
}

</style>
