<template>
    <div
        class="z-accordion"
        :class="{'z-accordion--nested': nested}"
    >
        <slot />
    </div>
</template>

<script setup>
import {provide, defineProps, ref, inject, onMounted} from 'vue'

const props = defineProps({
    nested: {
        type: Boolean,
        default: false
    },
    multiple: {
        type: Boolean,
        default: false
    },
    initial: {
        type: String
    }
})
const nestedHandler = ref(null)
const foldersHandler = ref(null)
const isMultiple = ref(props.multiple)
const openedItem = ref(new Set())

// Nested
if (props.nested) {
    nestedHandler.value = inject('nested')
    isMultiple.value = inject('is-multiple')
    foldersHandler.value = inject('folders')
}
// end Nested

const changeOpenedItem = (id) => {
    if (openedItem.value.has(id)) {
        openedItem.value.delete(id)
        return
    }

    if (isMultiple.value) {
        openedItem.value.add(id)
        return
    }

    if (props.nested && nestedHandler.value !== null) {
        nestedHandler.value()
    }

    openedItem.value.clear()
    openedItem.value.add(id)
}

provide('accordion-item', {
    openedItem,
    changeOpenedItem
})

provide('is-multiple', isMultiple.value)

onMounted(() => {
    if (props.initial) {
        changeOpenedItem(props.initial)
    }
    if (props.nested && foldersHandler.value !== null) {
        foldersHandler.value()
    }
})
</script>

<style lang="scss" src="./index.scss"></style>
