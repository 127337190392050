<template>
    <div :class="[
        'z-radio',
        { 'is-error': error },
        { 'is-disabled': disabled }
    ]">
        <div class="z-radio__list">
            <div
                class="z-radio__item"
                v-for="(item, index) in data"
                :key="index"
            >
                <label class="z-radio__label">
                    <input
                        class="z-radio__input"
                        type="radio"
                        :name="name"
                        :value="item.value"
                        v-model="radioValue"
                        @change="onChange"
                    >
                    <span class="z-radio__circle"></span>
                    <span class="z-radio__text" v-html="item.text"></span>
                </label>
            </div>
        </div>
        <span
            :class="['z-radio__error', errorClass]"
            v-if="error"
            v-html="error"
        ></span>
    </div>
</template>

<script>
export default {
    name: 'z-radio',
    props: {
        data: {
            type: Array,
            default: () => [],
            required: true
        },
        required: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            required: true
        },
        errorClass: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        disabled: Boolean
    },
    data () {
        return {
            localValue: this.value,
            error: '',
            text: {
                error: {
                    ru: 'Поле обязательно для заполнения',
                    en: 'Required field',
                    cn: '必填项目'
                }
            }
        }
    },
    computed: {
        radioValue: {
            get () {
                if (typeof this.value !== 'undefined') return this.value // для работы без v-model
                return this.localValue
            },
            set (value) {
                this.$emit('input', value)
                this.localValue = value
            }
        }
    },
    methods: {
        onChange () {
            this.$nextTick(this.validate)
            this.$emit('change', this.localValue)
        },
        validate () {
            if (this.required && !this.radioValue) {
                this.error = this.$root.localize(this.text.error)
            } else {
                this.error = ''
            }
        }
    }
}
</script>

<style lang="scss">

.z-radio {
    $parent: &;
    overflow: hidden;
    font-size: var(--textSize);

    &__list {
        margin-top: -1rem;
    }

    &__item {
        display: flex;
        margin-top: 1rem;
    }

    &__label {
        display: inline-flex;
        cursor: pointer;

        &:hover {
            #{$parent}__circle {
                border-color: var(--formBorderColorHover);
            }
        }
    }

    &__circle {
        display: block;
        width: 24px;
        height: 24px;
        border: var(--formBorderWidth) solid var(--formBorderColorDefault);
        transition: all 0.2s ease-in;
        flex-shrink: 0;
        cursor: pointer;
        border-radius: 50%;
        position: relative;
        margin-top: 0;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 9px;
            height: 9px;
            opacity: 0;
            background: var(--formBorderColorFilledAccent);
            transition: opacity 0.2s ease-in;
            border-radius: 50%;
        }
    }

    &__input {
        display: none;

        &:checked {
            + #{$parent}__circle {
                border-color: var(--formBorderColorFilled);
                background: var(--formBgColor);

                &:after {
                    opacity: 1;
                }
            }
        }
    }

    &__text {
        font-size: 1em;
        margin-left: 1em;
        font-weight: 400;
        color: var(--formTextColorDefault);
        margin-top: 0;
    }

    &__error {
        display: block;
        margin-top: 10px;
        font-size: var(--errorTextSize);
        color: var(--errorTextColor);
        position: relative;
    }

    // error state
    &.is-error {
        #{$parent}__circle {
            border-color: var(--formBorderColorError);
            background-color: var(--formBgColorError);
        }

        #{$parent}__text {
            color: var(--errorTextColor);
        }
    }

    // disabled state
    &.is-disabled {
        pointer-events: none;

        #{$parent}__circle {
            border-color: var(--formBorderColorDisabled);
            background-color:var(--formBgColorDisabled);
        }

        #{$parent}__text {
            color: var(--formTextColorDisabled);
        }
    }
}

</style>
