<template>
    <div
        class="z-image"
        :class="[
            {'z-image--background': background},
            {'z-image--border-radius': borderRadius}
        ]"
        :style="backgroundStyles"
        :alt="alt"
    >
        <img
            v-if="!background"
            :src="src"
            class="z-image__img"
            :alt="alt"
            :width="this.width"
            :height="this.height"
        >

        <div
            v-if="overlay"
            class="z-image__overlay"
            :style="{background: overlay}"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'z-image',
    props: {
        src: {
            type: String,
            required: true
        },
        overlay: String,
        background: {
            type: Boolean,
            default: false
        },
        width: String,
        height: String,
        alt: String,
        borderRadius: {
            type: Boolean,
            default: false
        },
        paddingTop: String
    },
    computed: {
        backgroundStyles () {
            return {
                width: this.background ? `${this.width}px` : null,
                height: this.background ? `${this.height}px` : null,
                'background-image': this.background ? `url(${this.src})` : null,
                'padding-top': this.paddingTop ? `${this.paddingTop}%` : null
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
