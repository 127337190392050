import { localize } from '@/utils/i18n'

export default {
    errors: {
        required: localize({
            ru: 'Поле обязательно для заполнения',
            en: 'Required field',
            cn: '填项目'
        }),
        invalid: {
            email: localize({
                ru: 'Формат для e-mail указан неверно',
                en: 'Invalid email format',
                cn: '无效的电子邮件格式'
            }),
            url: localize({
                ru: 'Неверный формат ссылки',
                en: 'Invalid link format'
            }),
            text: localize({
                ru: 'Допускаются только буквы и спецсимволы',
                en: 'Only letters and special characters are allowed',
                cn: '只允许使用字母和特殊字符'
            }),
            tel: localize({
                ru: 'Допускаются только цифры и спецсимволы',
                en: 'Only numbers and special characters are allowed',
                cn: '只允许使用数字和特殊字符'
            }),
            pattern: localize({
                ru: 'Неверный формат данных',
                en: 'Incorrect data format'
            }),
            minVal: localize({
                ru: 'Минимальное допустимое значение',
                en: 'Minimum allowable value'
            }),
            maxVal: localize({
                ru: 'Максимальное допустимое значение',
                en: 'Maximum allowable value'
            })
        }
    }
}