<template>
    <div class="news-filter" v-if="data">
        <div class="row">
            <div class="col-default-12">
                <z-input
                    placeholder="Поиск по названию"
                    v-model="params.q"
                    @input="onInput"
                    is-filter
                />
            </div>
        
            <div class="col-default-6 col-mobile-12">
                <z-select
                    v-if="data.type"
                    :options="data.type"
                    placeholder="Выберите тип"
                    name="type"
                    v-model="params.type"
                    @change="onFilterChange"
                    is-filter
                ></z-select>
            </div>
            <div class="col-default-6 col-mobile-12">
                <z-select
                    v-if="data.subject"
                    :options="data.subject"
                    placeholder="Выберите тему"
                    name="subject"
                    v-model="params.subject"
                    @change="onFilterChange"
                    multi
                    is-filter
                ></z-select>
            </div>

            <div class="col-default-6 col-mobile-12">
                <div class="datepickers-range">
                    <z-date-picker
                        placeholder="с"
                        is-filter
                        @change="onFilterChange"
                        v-model="params.from"
                    />
                    <span class="datepickers-range__range"></span>
                    <z-date-picker
                        placeholder="по"
                        is-filter
                        @change="onFilterChange"
                        v-model="params.to"
                    />
                </div>
            </div>
        </div>
        <div class="news-filter__clear-wrapper" v-if="isFilterActive">
            <z-link
                icon-position="right"
                icon="tools/close-small"
                @click="clear"
            >Сбросить фильтр</z-link>
        </div>
    </div>
</template>

<script>
import { debounce } from 'throttle-debounce'
import {
    queryString,
    clearEmptyParams,
    getGetParams
} from '@/utils/filterUtils'

export default {
    name: 'news-filter',
    props: {
        data: Object
    },
    data () {
        return {
            emits: ['change', 'update-params'],
            params: {
                q: '',
                from: '',
                to: '',
                type: '',
                subject: []
            },
            options: [
                {
                    'id': 1,
                    'selected': false,
                    'disabled': false,
                    'text': 'Развитие форматов',
                },
                {
                    'id': 2,
                    'selected': false,
                    'disabled': false,
                    'text': 'Конференции',
                },
                {
                    'id': 3,
                    'selected': false,
                    'disabled': false,
                    'text': 'Сделки слияния и поглощения',
                }
            ]
        }
    },
    computed: {
        isFilterActive () {
            let active = false

            for (const key in this.params) {
                if (Array.isArray(this.params[key])) {
                    if (this.params[key].length) {
                        active = true
                        break
                    }
                } else {
                    if (this.params[key]) {
                        active = true
                        break
                    }
                }
            }

            return active
        }
    },
    methods: {
        onInput: debounce(500, function () {
            this.onFilterChange()
        }),
        onFilterChange () {
            this.$emit('change', this.params)
            queryString(clearEmptyParams(this.params))
        },
        getParams () {
            this.params = { ...this.params, ...getGetParams() }
            this.$emit('update-params', this.params)
        },
        clear () {
            this.params = {
                q: '',
                from: '',
                to: '',
                type: '',
                subject: []
            }
            this.onFilterChange()
        }
    },
    created () {
        this.getParams()
    }
}
</script>

<style lang="scss">
.datepickers-range {
    display: flex;
    align-items: center;
    width: 100%;
    
    &__range {
        display: block;
        width: 12px;
        height: 2px;
        background: $token-colors-black-30;
        margin: 0 8px;
        flex-grow: 0;
    }

    .z-date-picker {
        flex-grow: 1;
    }
}

.news-filter {
    .row {
        @include margin-level(top, XS, true);

        > [class*="col-"] {
            @include margin-level(top, XS);
        }
    }

    &__clear-wrapper {
        display: flex;
        justify-content: flex-end;
    }
}
</style>