<template>
    <section class="mainpage-headings">
        <div class="container">
            <div class="row">
                <div class="col-default-10 col-default-offset-1 col-tablet-12 col-tablet-offset-0">
                    <z-caption class="u-bottom-margin--l-important" tag="h2" size="4xl" weight="700">О&nbsp;нас</z-caption>
                    <p class="mainpage-headings__description">М.Тех занимается разработкой ИТ-решений для бизнеса, а&nbsp;также предоставляет услуги по&nbsp;внедрению и&nbsp;поддержке цифровых сервисов. Всего более 100&nbsp;ИТ-продуктов на&nbsp;основе современной микросервисной архитектуры и&nbsp;облачной инфраструктуры, с&nbsp;использованием больших данных, биометрии, ИИ&nbsp;и&nbsp;машинного обучения.</p>
                </div>
            </div>
            <div class="mainpage-headings-cards cards">
                <card-flip class="cards__item">
                    <template v-slot:title>
                        <p class="black-95-text u-semi-bold text-size-l">Мобильная<br>платформа</p>
                    </template>
                    <template v-slot:content>
                        <p class="white-text u-bottom-margin--2xs u-semi-bold text-size-l">Мобильная платформа</p>
                        <p>Основа выстраивания бесшовного опыта в&nbsp;клиентском сервисе и&nbsp;объединения каналов продаж через смартфон, а&nbsp;также развития удобных сервисов для продавцов, логистов и&nbsp;других сотрудников компании. Разработка включает мобильное приложение покупателя и&nbsp;мобильное приложение продавца-консультанта и&nbsp;кладовщика, а&nbsp;также сопутствующие сервисы и&nbsp;ИТ-продукты.</p>
                    </template>
                </card-flip>
                <card-flip class="cards__item">
                    <template v-slot:title>
                        <p class="black-95-text u-semi-bold text-size-l">Веб-сервисы</p>
                    </template>
                    <template v-slot:content>
                        <p class="white-text u-bottom-margin--2xs u-semi-bold text-size-l">Веб-сервисы</p>
                        <p>Внедрение бесшовных сервисов на&nbsp;всех этапах покупки товаров с&nbsp;помощью онлайн-платформ для ритейла. Создание конверсионных сценариев и&nbsp;развитие рекомендательных сервисов.</p>
                    </template>
                </card-flip>
                <card-flip class="cards__item">
                    <template v-slot:title>
                        <p class="black-95-text u-semi-bold text-size-l">Система планирования ассортимента, промо и&nbsp;цен</p>
                    </template>
                    <template v-slot:content>
                        <p class="white-text u-bottom-margin--2xs u-semi-bold text-size-l">Система планирования ассортимента, промо и&nbsp;цен</p>
                        <p>Объединяет поставщиков, ритейлеров и&nbsp;покупателей. Решение для коммерческих команд, позволяющее предлагать выгодные цены, запускать промо и&nbsp;планировать ассортимент на&nbsp;основе больших данных и&nbsp;математических алгоритмов.</p>
                    </template>
                </card-flip>
                <card-flip class="cards__item">
                    <template v-slot:title>
                        <p class="black-95-text u-semi-bold text-size-l">Решения для поставщиков</p>
                    </template>
                    <template v-slot:content>
                        <p class="white-text u-bottom-margin--2xs u-semi-bold text-size-l">Решения для поставщиков</p>
                        <p>Сервисы для эффективного ведения бизнеса и&nbsp;развития продаж поставщиками, включая личный кабинет, позволяющий в&nbsp;едином интерфейсе управлять товарами, ценами, промоактивностями, стоком, а&nbsp;также получать аналитику и&nbsp;общаться с&nbsp;командами поддержки в&nbsp;логистике и&nbsp;других подразделениях.</p>
                    </template>
                </card-flip>
                <card-flip class="cards__item">
                    <template v-slot:title>
                        <p class="black-95-text u-semi-bold text-size-l">Организация продуктовой разработки и&nbsp;повышение эффективности розницы</p>
                    </template>
                    <template v-slot:content>
                        <p class="white-text u-bottom-margin--2xs u-semi-bold text-size-l">Организация продуктовой разработки и&nbsp;повышение эффективности розницы</p>
                        <p>Сервисы организации работы продуктовых команд, повышения мотивации сотрудников розницы, биометрия и&nbsp;автоматизированный шеринг персонала.</p>
                    </template>
                </card-flip>
                <card-flip class="cards__item">
                    <template v-slot:title>
                        <p class="black-95-text u-semi-bold text-size-l">Корпоративные AI-сервисы</p>
                    </template>
                    <template v-slot:content>
                        <p class="white-text u-bottom-margin--2xs u-semi-bold text-size-l">Корпоративные AI-сервисы</p>
                        <p>Нейросеть разработана для автоматизации коммуникации с&nbsp;сотрудниками розницы. Компания развивает чат-боты для адаптации и&nbsp;обучения персонала, консультирования по&nbsp;корпоративным вопросам, помощи в&nbsp;разрешении трудностей с&nbsp;работой оборудования и&nbsp;ИТ-систем. Продавцам магазинов боты также помогают в&nbsp;решении большинства повседневных задач&nbsp;&mdash; от&nbsp;оформления отпуска до&nbsp;клиентской поддержки, что сокращает затраты компании в&nbsp;четыре раза.</p>
                    </template>
                </card-flip>
                <card-flip class="cards__item">
                    <template v-slot:title>
                        <p class="black-95-text u-semi-bold text-size-l">Логистическая платформа</p>
                    </template>
                    <template v-slot:content>
                        <p class="white-text u-bottom-margin--2xs u-semi-bold text-size-l">Логистическая платформа</p>
                        <p>Уникальная система планирования закупок, прогнозирования продаж и&nbsp;распределения товаров в&nbsp;облачной среде, основанная на&nbsp;алгоритмах машинного обучения. &laquo;Титан&raquo;&nbsp;&mdash; тиражируемое решение, не&nbsp;имеет ограничений по&nbsp;числу выпускаемых релизов, позволяет решать инциденты в&nbsp;разы быстрее большинства зарубежных аналогов, обрабатывает весь массив данных по&nbsp;объектам в&nbsp;течение нескольких часов.</p>
                    </template>
                </card-flip>
                <card-flip class="cards__item">
                    <template v-slot:title>
                        <p class="black-95-text u-semi-bold text-size-l">Data-сервисы</p>
                    </template>
                    <template v-slot:content>
                        <p class="white-text u-bottom-margin--2xs u-semi-bold text-size-l">Data-сервисы</p>
                        <p>Компания развивает целый ряд решений для автоматизации и&nbsp;повышения эффективности ритейл-бизнеса во&nbsp;многих направлениях: коммуникации с&nbsp;клиентами, формировании персонализированных предложений, работы с&nbsp;ассортиментом и&nbsp;промо-планом. В&nbsp;частности, модели на&nbsp;машинном обучении на&nbsp;основе больших данных рекомендуют пользователям подходящие устройства, сопутствующие товары и&nbsp;аксессуары.</p>
                    </template>
                </card-flip>
                <card-flip class="cards__item">
                    <template v-slot:title>
                        <p class="black-95-text u-semi-bold text-size-l">Речевая аналитика и&nbsp;клиентский чат-бот</p>
                    </template>
                    <template v-slot:content>
                        <p class="white-text u-bottom-margin--2xs u-semi-bold text-size-l">Речевая аналитика и&nbsp;клиентский чат-бот</p>
                        <p>Разработка чат-ботов с&nbsp;широким набором сценариев, конверсионными и&nbsp;рекомендательными навыками. Архитектура решений опирается на&nbsp;коммуникационную платформу на&nbsp;базе Edna и&nbsp;интеграционную платформу на&nbsp;базе Just&nbsp;AI, бизнес-сервисы микросервисной платформы и&nbsp;NLP-сервисы, например, для мониторинга диалогов в&nbsp;реальном времени и&nbsp;обогащения ботов необходимой информацией.</p>
                    </template>
                </card-flip>
            </div>
        </div>
    </section>
</template>

<script setup></script>

<style lang="scss">
.mainpage-headings {
    @include padding-level (top, 4XL);
    @include padding-level (bottom, 4XL);
    background-color: $token-colors-form-bg-30;
    position: relative;

    &:before {
        content: '';
        background-image: url('/images/mainpage/bg-headings.svg');
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: 135% auto;
        background-repeat: repeat-x;
        background-position: 0 0;
        max-height: 640px;
        pointer-events: none;

        @media screen and (min-width: 1921px) {
            background-size: 2600px auto;
        }
    }

    &__description {
        @include typo-level (XL);
    }
}

.mainpage-headings-cards {
    @include margin-level (top, 3XL);
    display: grid;
    gap: 32px;


    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: minmax(300px, auto);


    @include breakpoint (laptop) {
        grid-template-columns: repeat(4, 1fr);
    }

    @include breakpoint (tablet) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint (v-tablet) {
        gap: 20px;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(9, minmax(100px, auto)); //я хз захватить все строки, без указания их кол-ва
    }

    .cards {
        margin-top: $token-spacers-3-xl;

        &__item {
            @include breakpoint (laptop) {
                &:last-child {
                    grid-column: 3 / 5;
                }

                &:nth-last-child(2) {
                    grid-column: 1 / 3;
                }
            }

            @include breakpoint (tablet) {
                &:last-child {
                    grid-column: auto;
                }

                &:nth-last-child(2) {
                    grid-column: auto;
                }
            }

            @include breakpoint (v-tablet) {
                min-height: 75px;
                perspective: none;

                .card-flip__front {
                    height: 100%;
                }

                .card-flip__back {
                    height: fit-content;
                    z-index: 1001;
                }
            }

            @include breakpoint (mobile) {
                min-height: auto;
                .card-flip__front {
                    display: flex;
                    align-items: center;
                    height: 100%;
                }

                .card-flip__back {
                    height: auto;
                }
            }
        }
    }
}
</style>
