<template>
    <nav
        class="navigation-mobile"
        aria-label="Main navigation"
    >
        <a
            class="navigation-mobile__burger"
            href="javascript:void(0);"
            :class="{'is-active': burgerMenu}"
            @click="toggleDropdown()"
        >
            <z-icon
                class="navigation-mobile__burger--open"
                name="tools/burger"
            />
            <z-icon
                class="navigation-mobile__burger--close"
                name="tools/close"
            />
        </a>
        <div class="navigation-mobile__dropdown" v-if="burgerMenu">
            <navigation-mobile-body :icons="icons"/>
        </div>
    </nav>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import NavigationMobileBody from './components/NavigationMobileBody.vue'

export default {
    name: 'navigation-mobile',
    components: {
        NavigationMobileBody
    },
    props: {
        isIndex: Boolean,
        staticLinks: {
            type: Array,
            default: null
        },
        icons: {
            type: Object,
            default: () => ({
                external: 'tools/external-2',
                backlink: 'arrows/caret',
                link: 'arrows/caret'
            })
        }
    },
    provide () {
        return {
            closeMenu: this.toggleDropdown
        }
    },
    computed: {
        ...mapGetters(['burgerMenu'])
    },
    watch: {
        burgerMenu (next) {
            let header = document.querySelector('.header')

            header.classList.toggle('is-open', next && header)
            document.body.classList.toggle('is-disabled', next && header)
            document.body.classList.toggle('mobile-nav-opened', next && header)
        }
    },
    methods: {
        ...mapActions(['ToggleBurgerMenuAction']),
        toggleDropdown () {
            this.ToggleBurgerMenuAction()
        }
    }
}
</script>

<style lang="scss">
@import "./vars.scss";

.navigation-mobile {
    $parent: &;
    display: flex;
    height: 100%;
    align-items: center;

    a {
        text-decoration: none;
        color: inherit;
    }

    &__burger {
        display: block;
        width: 24px;
        height: 24px;

        .z-icon {
            width: 100%;
            height: 100%;
        }

        &--open {
            display: block;

            .is-active & {
                display: none;
            }

            #{$parent}--index & {

                path {
                    fill: $token-colors-white;
                }
            }
        }

        &--close {
            display: none;

            .is-active & {
                display: block;
            }
        }
    }

    &__dropdown {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        background-color: $token-colors-form-bg-30;
        padding-bottom: 12px;

        @include breakpoint(v-tablet) {
            top: 130px; // header height
        }

        @include breakpoint(mobile) {
            top: 92px; // header height
        }
    }
}
</style>
