function toggleBodyScroll () {
    let scrollY = 0

    function disableScroll () {
        if (document.body.style.position !== 'fixed') {
            scrollY = window.pageYOffset
            document.body.style.position = 'fixed'
            document.body.style.top = `-${scrollY}px`
            document.body.style.width = '100%'
        }
    }
    
    function enableScroll () {
        document.body.style.position = ''
        document.body.style.top = ''
        document.body.style.width = ''
        window.scroll(0, scrollY)
    }

    return {enableScroll, disableScroll}
}

export {
    toggleBodyScroll
}