<template>
    <div
        class="z-select"
        v-if="options.length"
        :class="{
            'z-select--filter': isFilter,
            'z-select--clearable': isClearable,
            'is-opened': isOpened,
            'is-errored': isValid === false,
            'is-valid': isValid && !isFilter,
            'is-filled': selected && selected.length,
            'is-required': required,
            'z-select--multi': multi,
        }"
        v-click-outside="onClickOutside"
    >
        <span
            class="z-select__title"
            v-if="title"
            v-html="title"
        ></span>
        <div class="z-select__wrapper">
            <div class="z-select__container" @click="setOpenState(!isOpened)" tabindex="0">
                <span
                    v-if="placeholder"
                    v-html="placeholder"
                    class="z-select__placeholder"
                ></span>
                <div class="z-select__selected">
                    <span
                        class="z-select__value"
                        v-html="getValueText(selected)"
                        v-if="!multi"
                    ></span>
                    <template v-else>
                        <span
                            class="z-select__value"
                            v-for="(item, index) in selected"
                            
                            :key="index"
                        >
                            <span v-html="getValueText(item)"></span>
                            <span
                                @click.stop="removeSelectedOption(item)"
                                class="z-select__value-delete"
                            ></span>
                        </span>
                    </template>
                    <!-- <input
                        class="z-select__input"
                        type="text"
                        disabled
                        :value="valueText"
                        autocomplete="off"
                    > -->
                </div>
                <span
                    v-if="isClearable && selected && selected.length"
                    class="z-select__clear"
                    @click.stop="clear"
                ></span>
                <span class="z-select__arrow"></span>
            </div>
            <div class="z-select__dropdown" v-if="isOpened">
                <div
                    class="z-select__options"
                    v-for="(group, groupIndex) in groupedOptions"
                    :key="groupIndex"
                >
                    <span
                        class="z-select__options-caption"
                        v-html="group.name"
                        v-if="group.name"
                    ></span>
                    <ul
                        v-if="group.items && group.items.length"
                        class="z-select__options-group"
                    >
                        <li
                            class="z-select__option"
                            v-for="option in group.items"
                            :class="{
                                'is-selected': isOptionSelected(option.id),
                                'is-disabled': option.disabled
                            }"
                            @click="onSelectOption(option.id)"
                            :key="option.id"
                        >
                            <vue-raw :raw="option.text" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <span
            class="z-select__error"
            v-if="error && isValid === false"
            v-html="error"
        ></span>
        
    </div>
</template>

<script setup>
import { ref, computed, nextTick } from 'vue'
import { localize } from '@/utils/i18n'

const props = defineProps({
    modelValue: [String, Array, Number],
    options: {
        type: Array,
        default: () => []
    },
    placeholder: String,
    title: String,
    clearable: Boolean,
    isFilter: Boolean,
    required: Boolean,
    multi: Boolean
})

const emit = defineEmits([
    'update:modelValue',
    'change',
    'input'
])

// static data
const errorText = localize({
    ru: 'Поле обязательно для заполнения',
    en: 'Required field',
    cn: '填项目'
})

// computed
const isClearable = computed(() => props.isFilter || props.clearable)
const selected = computed({
    get() {
        if (props.modelValue !== undefined) return props.modelValue // для работы без v-model
        return localValue.value
    },
    set(value) {
        localValue.value = value
        emit('update:modelValue', value)
    }
})
const groupedOptions = computed(() => {
    let result = {}
    props.options.forEach(item => {
        if (item.group) {
            if (!result[item.group]) result[item.group] = {}
            if (!result[item.group].items) result[item.group].items = []
            result[item.group].name = item.group
            result[item.group].items.push(item)
        } else {
            if (!result.nogroup) result.nogroup = {}
            if (!result.nogroup.items) result.nogroup.items = []
            result.nogroup.name = ''
            result.nogroup.items.push(item)
        }
    })

    return result
})

// ref
let isOpened = ref(false)
let localValue = ref('') // это значение нужно для работы без v-model, никаких привязок к нему делать не надо
let isValid = ref(null) // null первичное значение, чтобы пока пользователь не убрал фокус с поля не было подствеки валидно/невалидно
let error = ref('')

// methods
const clear = () => {
    emit('update:modelValue', '')
    emit('change', selected.value)
    localValue.value = ''
    isOpened.value = false
    nextTick(validate)
}
const setOpenState = state => isOpened.value = state
const onSelectOption = id => {
    if (!props.multi) {
        // selected.value = id
        localValue.value = id
        emit('update:modelValue', id)
        isOpened.value = false
    } else {
        if (!localValue.value) localValue.value = []
        localValue.value.includes(id) ? removeSelectedOption(id) : localValue.value.push(id)
        emit('update:modelValue', localValue.value)
    }

    emit('change', selected.value)
    nextTick(validate)
}
const onClickOutside = () => setOpenState(false)
const getValueText = id => props.options.find(item => item.id === id)?.text
const isOptionSelected = id => {
    if (!props.multi) return id === selected.value
    return selected.value.includes(id)
}
const removeSelectedOption = id => {
    const index = selected.value.indexOf(id)
    localValue.value.splice(index, 1)
    emit('update:modelValue', localValue)
} 
const validate = () => {
    if (props.required)  {
        if (!(selected.value && selected.value.length)) {
            isValid.value = false
            error.value = errorText
        } else {
            isValid.value = true
            error.value = ''
        }
    } else {
        isValid.value = null
        error.value = ''
    }
}

defineExpose({
    validate
})
</script>

<style lang="scss">
.z-select {
    $parent: &;

    position: relative;
    font-size: var(--textSize);

    &__container {
        position: relative;
        cursor: pointer;
        border-radius: var(--borderRadius);
        border: var(--formBorderWidth) solid var(--formBorderColorDefault);
        width: 100%;
        box-sizing: border-box;
        transition: border-color 0.2s ease-in;
        padding: var(--fieldPaddingM);
        height: var(--fieldHeightM);

        padding-right: calc(var(--clearRight) + var(--arrowSize) + 10px + var(--iconSize) + 10px);
    }

    &__wrapper {
        position: relative;
    }

    &__selected {
        color: var(--formTextColorDefault);
        line-height: 1.4;
        overflow: hidden;
        text-overflow: ellipsis;
        pointer-events: none;
        white-space: nowrap;
    }

    &__value {
        & + & {
            margin-left: 8px;
        }
    }

    &__value-delete {
        margin-left: 4px;
        width: calc(var(--iconSize) * 0.8);
        height: calc(var(--iconSize) * 0.8);
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        vertical-align: middle;
        margin-bottom: 0.2em;
        cursor: pointer;
        pointer-events: auto;
        background-image: svg-load('tools/close.svg', fill=$iconColorDefault);

        &:hover {
            background-image: svg-load('tools/close.svg', fill=$iconColorHover);
        }
    }

    &__clear {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
        width: var(--iconSize);
        height: var(--iconSize);
        margin-top: 0;
        right: calc(var(--clearRight) + var(--arrowSize) + 10px);
        background-image: svg-load('tools/close.svg', fill=$iconColorDefault);

        &:hover {
            background-image: svg-load('tools/close.svg', fill=$iconColorHover);
        }
    }

    &__arrow {
        width: var(--arrowSize);
        height: var(--arrowSize);
        background-image: svg-load('arrows/caret.svg', fill=$iconColorDefault);
        display: block;
        position: absolute;
        top: 50%;
        right: 16px;
        background-position: 50% 50%;
        transform: translateY(-50%) scale(1, -1);
        background-size: contain;
        background-repeat: no-repeat;
        pointer-events: none;

        &:hover {
            background-image: svg-load('arrows/caret.svg', fill=$iconColorHover);
        }
    }

    &__placeholder {
        padding: 0px 4px;
        display: block;
        max-width: 100%;
        position: absolute;
        top: 50%;
        left: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: var(--formPlaceholderColorDefault);
        background-color: transparent;
        pointer-events: none;
        overflow: hidden;
        transform: translateY(var(--placeholderTranslateY)) scale(1);
        transition: all .3s ease-in-out;
        font-size: 1em;
        line-height: 1.4;
        margin-top: 0;
        pointer-events: none;
    }

    &__error {
        font-size: var(--errorTextSize);
        color: var(--errorTextColor);
        position: relative;
    }

    &__dropdown {
        width: calc(100% + (var(--formBorderWidth)*2));
        position: absolute;
        left: calc(var(--formBorderWidth) * -1);
        z-index: 3;
        background-color: var(--formBgColor5);
        top: calc(100% + var(--formBorderWidth));
        // border: var(--formBorderWidth) solid var(--formBorderColorFocus);
        // border-top: none;
        max-height: 220px;
        overflow: auto;
        padding: 8px;

        &::-webkit-scrollbar {
            width: 8px !important;
            background: var(--formBgColor5);
        }

        &::-webkit-scrollbar-thumb {
            background: var(--formBgColor30);
            border-radius: 40px;
        }
    }

    &__options {
        margin: 0;
        margin-top: -4px;

        & + & {
            margin-top: 8px;
        }
    }

    &__option {
        font-size: var(--textSize);
        cursor: pointer;
        position: relative;
        padding: 6px 12px;
        color: var(--formTextColorDefault);
        transition: background 0.2s ease-in;
        margin-top: 4px;
        line-height: 1.4;
        border-radius: var(--borderRadius);

        &:hover {
            background: var(--formBgColor10);
        }

        &.is-selected {
            background: var(--formBgColor20);
        }

        &.is-disabled {
            opacity: .5;
            pointer-events: none;
        }
    }

    &__options-caption {
        font-weight: 600;
        padding: 6px 12px;
        display: block;
        color: $token-colors-black-40;
        font-size: 1em;

        // & + #{$parent}__options-group {
        //     padding-left: 1em;
        // }
    }

    &__options-group {
        margin: 0;
    }

    // filled state
    &.is-filled {
        border-color: var(--formBorderColorFilled);

        #{$parent}__placeholder {
            width: auto;
            transform: translateY(var(--placeholderFilledTranslateY));
            transition: all 0.2s ease-in;
            font-size: var(--filledPlaceholderTextSize);
            line-height: 1.2;
            background-color: var(--placeholderBg);
        }
    }

    //hover state
    &:hover {
        #{$parent}__container {
            border-color: var(--formBorderColorHover);
        }
    }

    // errored state
    &.is-errored {
        #{$parent}__container {
            border-color: var(--formBorderColorError);
        }
    }

    // valid state
    &.is-valid {
        #{$parent}__container {
            border-color: var(--formBorderColorValid);
        }
    }

        // opened
    &.is-opened {
        #{$parent}__container {
            border-color: var(--formBorderColorFocus);
        }

        #{$parent}__arrow {
            transform: translateY(-50%) scale(1, 1);
        }
    }

    &--filter {
        // filled state
        &.is-filled {
            #{$parent}__container {
                border-color: var(--formBorderColorFilledAccent);
            }
        }
    }
}

.z-select--multi {
    // $parent: '.z-select';

    .z-select__option {
        padding-left: calc(12px + var(--boxSizeS) + 8px);

        &:before {
            content: '';
            display: block;
            margin: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 1;
            transition: background-color 0.2s ease-in, background-image 0.2s ease-in, border 0.2s ease-in;
            left: 12px;
            border: var(--formBorderWidth) solid var(--formBorderColorDefault);
            border-radius: var(--borderRadius);
            width: var(--boxSizeS);
            height: var(--boxSizeS);
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-color: var(--formBgColor);
            background-size: 80%;
        }

        &:hover:before,
        &.is-selected:before {
            border-color: var(--formBorderColorFilled);
            background-image: svg-load('list/checkmark.svg', fill=$checkColor);
        }
    }
}
</style>