import {app} from './config/app'
import { Layout, HeaderLayout, FooterLayout } from './views/Layout'
import Files from './views/Files'
import { Bio, BioDetail } from './views/Bio'
import { News, NewsDetail } from './views/News'
import Committees from './views/Committees'
import DetailPage from './views/DetailPage'
import MainPage from './views/MainPage'
import VacanciesDetail from './views/Vacancies/VacanciesDetail.vue'

app.component('header-layout', HeaderLayout)
app.component('footer-layout', FooterLayout)
app.component('layout', Layout)
app.component('files', Files)
app.component('bio', Bio)
app.component('bio-detail', BioDetail)
app.component('news', News)
app.component('news-detail', NewsDetail)
app.component('committees', Committees)
app.component('detail-page', DetailPage)
app.component('main-page', MainPage)
app.component('vacancies-detail', VacanciesDetail)
