<template>
    <div :class="[
        'z-input',
        {
            'z-input--filter': isFilter,
            'z-input--clearable': isClearable,
        },
        {
            'is-focused': isFocused,
            'is-filled': value,
            'is-disabled': disabled,
            'is-required': required,
            'is-errored': isValid === false,
            'is-valid': isValid && !isFilter,
        }
    ]">
        <div class="z-input__wrapper">
            <label class="z-input__label">
                <p
                    class="z-input__title"
                    v-if="title"
                    v-html="title"
                ></p>
                <div class="z-input__container">
                    <input
                        class="z-input__input"
                        :name="name"
                        type="type"
                        v-model="value"
                        @blur="onBlur"
                        @focus="onFocus"
                        @change="onChange($event)"
                        @input="onInput($event)"
                        :disabled="disabled"
                        @keypress="onKeyPress"
                    />
                    <span
                        v-show="placeholder"
                        v-html="placeholder"
                        class="z-input__placeholder"
                    ></span>
                </div>
                
            </label>
            <span
                v-if="isClearable && value"
                class="z-input__clear"
                @click="clear"
            ></span>
        </div>
        <span
            class="z-input__error"
            v-html="error"
            v-if="error && isValid === false"
        ></span>
    </div>
</template>


<script setup>
import { computed, ref, nextTick } from 'vue'
import locales from './locales.js'
import {
    validateEmail,
    validateTel,
    validateUrl,
    validateTextOnly
} from './utils.js'

const props = defineProps({
    modelValue: String,
    isFilter: Boolean,
    clearable: Boolean,
    disabled: Boolean,
    required: Boolean,
    title: String,
    placeholder: String,
    name: String,
    type: {
        type: String,
        validator: prop => ['text', 'number', 'tel', 'email', 'link', 'text-only'].includes(prop),
        default: 'text'
    },
    minVal: [String, Number],
    maxVal: [String, Number],
    positive: {
        type: Boolean,
        default: true
    }
})

const emit = defineEmits([
    'update:modelValue',
    'change',
    'input'
])

// ref
let localValue = ref('') // это значение нужно для работы без v-model, никаких привязок к нему делать не надо
let isFocused = ref(false)
let isValid = ref(null) // null первичное значение, чтобы пока пользователь не убрал фокус с поля не было подствеки валидно/невалидно
let error = ref('')

// computed
const value = computed({
    get() {
        if (props.modelValue !== undefined) return props.modelValue // для работы без v-model
        return localValue.value
    },
    set(value) {
        localValue.value = value
        emit('update:modelValue', value)
    }
})
const isClearable = computed(() => props.isFilter || props.clearable)

// events
const onBlur = () => isFocused.value = false
const onFocus = () => isFocused.value = true
const onChange = e => emit('change', e.target.value)
const onInput = e => {
    emit('input', e.target.value)
    validate()
}
const onKeyPress = e => {
    console.log('keypress');
    if (props.type !== 'number') return
    if (props.positive && (e.charCode > 31 && (e.charCode < 48 || e.charCode > 57)) && e.charCode !== 46) {
        e.preventDefault()
    }
}

// methods
const clear = async () => {
    emit('update:modelValue', '')
    localValue.value = ''
    emit('input', '')
    emit('change', '')
    await nextTick()
    validate()
}

const validate = () => {
    if (props.required && !value.value) {
        isValid.value = false
        error.value = locales.errors.required
        return
    }

    // если поле необязательное и пустое, то сбрасываем все ошибки и не даем дальше валидировать пустое значение
    if (!props.required && !value.value) {
        isValid.value = null
        error.value = ''
        return
    }

    if (props.type === 'email' && !validateEmail(value.value)) {
        isValid.value = false
        error.value = locales.errors.invalid.email
        return
    }

    if (props.type === 'link' && !validateUrl(value.value)) {
        isValid.value = false
        error.value = locales.errors.invalid.url
        return
    }

    if (props.type === 'text-only' && !validateTextOnly(value.value)) {
        isValid.value = false
        error.value = locales.errors.invalid.text
        return
    }

    if (props.type === 'tel' && !validateTel(value.value)) {
        isValid.value = false
        error.value = locales.errors.invalid.tel
        return
    }

    if (props.type === 'number') {
        if (props.minVal && Number(value.value) < Number(props.minVal)) {
            isValid.value = false
            error.value = `${locales.errors.invalid.minVal} - ${props.minVal}`
            return
        }

        if (props.maxVal && Number(value.value) > Number(props.maxVal)) {
            isValid.value = false
            error.value = `${locales.errors.invalid.maxVal} - ${props.maxVal}`
            return
        }
    }

    value.value ? isValid.value = true : isValid.value = null
    error.value = ''
}

defineExpose({
    validate
})
</script>

<style lang="scss">
.z-input {
    $parent: &;
    font-size: var(--textSize);
    width: 100%;
    font-weight: 400;

    &__container {
        position: relative;
    }

    &__wrapper {
        position: relative;
    }

    &__clear {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
        width: var(--iconSize);
        height: var(--iconSize);
        margin-top: 0;
        right: var(--clearRight);
        background-image: svg-load('tools/close.svg', fill=$iconColorDefault);

        &:hover {
            background-image: svg-load('tools/close.svg', fill=$iconColorHover);
        }
    }

    &__label {
        display: block;
    }

    &__input {
        border-radius: var(--borderRadius);
        border: var(--formBorderWidth) solid var(--formBorderColorDefault);
        width: 100%;
        color: var(--formTextColorDefault);
        box-sizing: border-box;
        padding: var(--fieldPaddingM);
        transition: border-color 0.2s ease-in;
        line-height: 1.4;
        height: var(--fieldHeightM);

        // обнуления стиля для автозаполненных полей
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px white inset !important;
            transition: 0s;
        }
    }

    &__placeholder {
        padding: 0px 4px;
        display: block;
        max-width: 100%;
        position: absolute;
        top: 50%;
        left: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: var(--formPlaceholderColorDefault);
        background-color: transparent;
        pointer-events: none;
        overflow: hidden;
        transform: translateY(var(--placeholderTranslateY)) scale(1);
        transition: all .3s ease-in-out;
        font-size: 1em;
        line-height: 1.4;
        margin-top: 0;
    }

    &__error {
        font-size: var(--errorTextSize);
        color: var(--errorTextColor);
        position: relative;
        margin-left: 20px;
        display: block;
        line-height: 1.2;
        margin-top: 4px;
    }

    // hover state
    &:hover {
        #{$parent}__input {
            border-color: var(--formBorderColorHover);
        }
    }

    // filled state
    &.is-filled {
        #{$parent}__placeholder {
            width: auto;
            transform: translateY(var(--placeholderFilledTranslateY));
            font-size: var(--filledPlaceholderTextSize);
            line-height: 1.2;
            background-color: var(--placeholderBg);
        }

        #{$parent}__input {
            border-color: var(--formBorderColorFocus);
        }
    }

    // errored state
    &.is-errored {
        #{$parent}__input {
            border-color: var(--formBorderColorError);
        }
    }

     // valid state
    &.is-valid {
        #{$parent}__input {
            border-color: var(--formBorderColorValid);
        }
    }

    // focused state
    &.is-focused {
        #{$parent}__placeholder {
            width: auto;
            transform: translateY(var(--placeholderFilledTranslateY));
            font-size: var(--filledPlaceholderTextSize);
            line-height: 1.2;
            background-color: var(--placeholderBg);
        }

        #{$parent}__input {
            border-color: var(--formBorderColorFocus);
        }
    }

    // disabled state
    &.is-disabled {
        pointer-events: none;
    }

    &--clearable {
        #{$parent}__input {
            padding-right: calc(var(--clearRight) + var(--iconSize) + 8px);
        }
    }

    &--filter {
        // filled state
        &.is-filled {
            #{$parent}__input {
                border-color: var(--formBorderColorFilledAccent);
            }
        }
    }

    &.is-required {
        .z-input__placeholder {
            &:after {
                content: '*';
                color: var(--errorTextColor);
                margin-left: 4px;
            }
        }

        .z-input__error {
            &:before {
                content: '*';
                color: var(--errorTextColor);
                margin-right: 4px;
            }
        }
    }
}
</style>
