<template>
    <div class="bio" v-if="data.length" >
        <div class="row">
            <div
                class="col-default-3 col-tablet-4 col-v-tablet-6 col-mobile-12"
                v-for="(item, index) in data"
                :key="`bio-${index}`"
            >
                <a :href="item.detailPageUrl" class="bio__card">
                    <div
                        class="bio__avatar"
                        :style="`background-image: url('${getAvatar(item)}')`">
                    </div>
                    <span class="bio__name" v-html="item.name"></span>
                    <span class="text-size-s u-align-center black-60-text" v-html="item.position.value"></span>
                </a>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    name: 'bio',
    props: {
        source: {
            type: String,
            default: 'board-of-directors'
        }
    },
    data () {
        return {
            data: this.$root.app.components[this.source]?.items || [],
        }
    },
    methods: {
        getAvatar (item) {
            if (item.previewPicture.link) {
                return item.previewPicture.link
            }

            return item.gender.xmlId === 'female' ? '/images/female.jpg' : '/images/male.jpg'
        }
    }
}
</script>

<style lang="scss">
.bio {
    &__avatar {
        width: 100%;
        padding-top: 100%;
        @include margin-level(bottom, XS);
        background-position: center;
        background-size: cover;
        border-radius: 4px;
    }

    &__name {
        font-weight: 600;
        line-height: 1.15;
        @include typo-level(L);
        text-align: center;
        margin-bottom: $token-spacers-3-xs;
        transition: color $transition;
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: $token-spacers-3-xs;
        transition: background $transition;
        border-radius: 4px;
        padding: $token-spacers-2-xs;
        text-decoration: none;

        &:hover {
            cursor: pointer;
            background: $token-colors-bg-10;
        }
    }
}
</style>
