<template>
    <div class="card-flip" :class="{'card-flip--inverted': isBackShown && device === 'mobile'}" @click="showBack">
        <div class="card-flip__front">
            <div class="card-flip__front-wrapper">
                <slot name="title" />
            </div>
        </div>
        <div class="card-flip__back">
            <button class="card-flip__close" @click.stop="hideBack">
                <z-icon name="tools/close-small" width="24" height="24" />
            </button>
            <div class="card-flip__back-wrapper">
                <slot name="content" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useGetter } from '@/composable/useGetter'
import { toggleBodyScroll } from '@/utils/popupUtils.js'

const { device } = useGetter(['device'])
const { enableScroll, disableScroll } = toggleBodyScroll()
const isBackShown = ref(false)

const showBack = () => {
    if (device === 'mobile') {
        disableScroll()
    }
    return isBackShown.value = true
}

const hideBack = () => {
    if (device === 'mobile') {
        enableScroll()
    }
    return isBackShown.value = false
}
</script>

<style lang="scss">
.card-flip {
    $parent: &;

    @media screen and (min-width: calc($breakpoint-mobile + 1px)) {
        position: relative;
        perspective: 1000px;
        max-height: 300px;

        &__back,
        &__front {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            transition: 0.9s;
            backface-visibility: hidden;
        }

        &__back {
            transform: rotateX(180deg);
            opacity: 0;
            height: 100%;

            &:after {
                content: '';
                height: 40px;
                bottom: -7px; //компенсация scale
                position: absolute;
                width: calc((100% / 1.1) - 5px);
                left: 0;
                transform: scale(1.1);
                background: linear-gradient(0deg, rgba(80,83,101,1) 15%, rgba(80,83,101,0.55) 70%, rgba(80,83,101,0) 100%);
            }
        }

        &:hover {
            cursor: pointer;

            #{$parent}__front {
                transform: rotateX(180deg);
            }

            #{$parent}__back {
                opacity: 1;
                transform: rotateX(360deg) scale(1.1);
                cursor: auto;
            }
        }
    }

    &__front {
        background-color: $token-colors-white;
        position: relative;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            height: 5px;
            top: 0;
            left: 0;
            width: 100%;
            background: linear-gradient(90deg, #e31235 50%, #76bc21 50%);
            background-repeat: no-repeat;
            background-size: 100% auto;
        }
    }

    &__back {
        color: $token-colors-white;
        @include typo-level (XS);
        // font-size: 16px;
        line-height: 1.4;
        background-color: $token-colors-bg-20;
        overflow: hidden;
    }

    &__back-wrapper {
        padding: 24px;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;

        &::-webkit-scrollbar {
            width: 5px;
            border-radius: 36px;
            background: $token-colors-bg-15;
        }

        &::-webkit-scrollbar-thumb {
            background: $token-colors-primary;
            border-radius: 36px;
        }
    }

    &__front-wrapper {
        padding: 30px 24px;

        &::-webkit-scrollbar {
            background: $token-colors-bg-15;
        }

        &::-webkit-scrollbar-thumb {
            background: $token-colors-secondary;
            border-radius: 36px;
        }
    }

    &__close {
        display: none;
    }

    @include breakpoint (mobile) {
        &__back {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding-top: calc(24px + 24px);
            padding-bottom: 24px;
            z-index: 5;
            opacity: 0;
            transition: opacity $transition;
            pointer-events: none;
        }

        &__back-wrapper {
            padding-top: 0;
            padding-bottom: 0;

            &::-webkit-scrollbar {
                width: 1px;
            }
        }

        &__close {
            border: none;
            padding: 0;
            background: none;
            position: absolute;
            display: block;
            right: 24px;
            top: 16px;
        }

        &--inverted {
            .card-flip__back {
                pointer-events: all;
                opacity: 1;
            }
        }
    }
}

</style>
