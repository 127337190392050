<template>
    <div>
        <news-filter
            @change="onFilterChange"
            @update-params="onUpdateParams"
            :data="filter"
        />

        <results class="u-top-margin--m" :data="items" />

        <z-pagination
            :data="paginationData"
            @change-page="onPageChange"
            class="u-top-margin--m-important"
        >
        </z-pagination>
    </div>
</template>

<script>
import { getNews } from '@/api/news'
import { throttle } from 'throttle-debounce'
import NewsFilter from './components/Filter.vue'
import Results from './components/Results.vue'

export default {
    name: 'news',
    components: {
        NewsFilter,
        Results
    },
    data () {
        return {
            params: {
                page: 1,
                q: '',
                from: '',
                to: '',
                type: '',
                subject: ''
            },
            data: this.$root.app.components.news,
            filter: this.$root.app.components.news?.filter,
            nav: this.$root.app.components.news?.nav,
            items: this.$root.app.components.news?.items,
            requestType: 'change-page'
        }
    },
    computed: {
        paginationData () {
            return ({
                currentPage: this.nav?.current || 1,
                totalItems: this.nav?.count || 0,
                totalPages: this.nav?.total || 0,
                pageRange: 1
            })
        }
    },
    methods: {
        onFilterChange (data) {
            this.params = { ...this.params, ...data }
            this.isLoading = true
            this.send()
        },
        onPageChange (data) {
            this.requestType = data.type
            this.params.page = data.page
            this.send()
        },
        send: throttle(500, function () {
            getNews(this.params)
                .then(response => {
                    this.nav = response.nav
                    this.filter = response.filter

                    if (this.requestType === 'change-page') {
                        this.items = response.items
                    } else {
                        this.items = this.items.concat(response.items)
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.isLoading = false
                })
        }),
        onUpdateParams (data) {
            this.params = { ...this.params, ...data }
        }
    }
}
</script>

<style lang="scss">
    
</style>