<template>
    <div class="navigation-mobile-body">
        <div class="navigation-mobile-body__header">
            <div class="container">
                <div v-if="currentLevel.header"
                     class="navigation-mobile-body__item navigation-mobile-body__item--backlink">
                    <span class="navigation-mobile-body__item-inner" @click="backToLevel">
                        <z-icon
                            class="navigation-mobile-body__arrow navigation-mobile-body__arrow--left"
                            :name="icons.backlink"
                            dir="left"
                        />
                        <a href="javascript:void(0);">
                            <span>
                                {{ currentLevel.header }}
                            </span>
                        </a>
                    </span>
                </div>
            </div>
        </div>
        <div class="navigation-mobile-body__submenu">
            <div class="navigation-mobile-body__submenu-wrapper container">
                <ul class="navigation-mobile-body__list">
                    <li v-if="currentLevel.parent" class="navigation-mobile-body__item navigation-mobile-body__item--parent">
                        <component
                            :is="currentLevel.parent.pageParams.redirect ? 'span' : 'a'"
                            :href="currentLevel.parent.link"
                            v-html="currentLevel.parent.name"
                            class="navigation-mobile-body__item-inner"
                            :class="{'navigation-mobile-body__item-inner--redirect' : currentLevel.parent.pageParams.redirect}"
                        />
                    </li>
                    <li
                        class="navigation-mobile-body__item"
                        v-for="(item, index) in currentLevel.items"
                        :key="index"
                        :class="[{'is-active': checkActiveState(item)}, {'navigation-mobile-body__item--submenu': levelCount !== 0}]"
                    >
                        <template v-if="item.items && item.items.length">
                            <template v-if="showAlternateLink">
                                <accordion-link :arrow-icon="icons.link" :item-parent="item">
                                    <span v-html="item.name"/>
                                </accordion-link>
                            </template>
                            <span v-else class="navigation-mobile-body__item-inner" @click="changeLevel(item, header)">
                                <span v-html="item.name"/>
                                <z-icon
                                    class="navigation-mobile-body__arrow navigation-mobile-body__arrow--right"
                                    :name="icons.link"
                                    dir="right"
                                />
                            </span>
                        </template>
                        <a
                            v-else
                            class="navigation-mobile-body__item-inner"
                            :href="item.link"
                            :title="item.name"
                            :target="item.params && item.params.BLANK ? '_blank' : null"
                            @click="closeMenu"
                        >
                            <span>
                                <span v-html="item.name"/>
                                <z-icon
                                    class="navigation-mobile-body__external-icon"
                                    v-if="item.params.EXTERNAL"
                                    :name="icons.external"
                                />
                            </span>
                        </a>
                    </li>
                </ul>
                <div class="navigation-mobile-body__footer">
                    <div class="contacts">
                        <z-link href="tel:+74956442848" theme="dark" :underlined="false" class="u-black" size="xl">+7 (495) 644-28-48</z-link>
                        <span class="contacts__worktime">Пн. — пт.: 10:00-19:00 по Москве</span>
                        <div class="contacts__socials socials-list">
                            <a class="socials-list__item" href="https://t.me/mvideoeldorado_live" target="_blank">
                                <z-icon name="social/telegram" width="40" height="40"/>
                            </a>
                            <a class="socials-list__item" href="https://habr.com/ru/companies/mvideo/articles/" target="_blank">
                                <z-icon name="social/habr" width="40" height="40"/>
                            </a>
                            <a class="socials-list__item" href="https://vc.ru/mvideoeldorado/" target="_blank">
                                <z-icon name="social/vc" width="40" height="40"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AccordionLink from './AccordionLink.vue'

export default {
    name: 'navigation-mobile-body',
    inject: ['closeMenu'],
    components: {
        AccordionLink
    },
    props: {
        icons: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            top: this.$root.app.components.navigation.top,
            text: {
                back: 'В главное меню'
            },
            levelMap: [],
            levelCount: 0,
            currentLevel: {
                items: [],
                header: null,
                parent: null
            }
        }
    },
    mounted () {
        this.levelMap.push({
            items: this.top,
            header: null,
            parent: null
        })
        this.updateCurrentLevel()
    },
    computed: {
        header () {
            if (this.currentLevel.parent === null) {
                return 'Назад'
            }
            return this.currentLevel.parent.name
        },
        maxLevels () {
            const collection = new Set()
            this.levelsCounter(this.top, collection)
            return Math.max(...collection)
        },
        showAlternateLink () {
            return this.levelCount === this.maxLevels - 2
        }
    },
    methods: {
        changeLevel (item, header) {
            this.levelCount = item.level
            this.levelMap.push(this.adaptDataToLevel(item, header))
            this.updateCurrentLevel()
        },
        backToLevel () {
            this.levelCount -= 1
            this.levelMap.splice(this.levelMap.length - 1, 1)
            this.updateCurrentLevel()
        },
        checkActiveState (item) {
            if (item.active === true) return true

            for (let i = 0; i < item.items?.length; i++) {
                if (item.items[i].active === true) return true
            }

            return false
        },
        // levelsCounter считает рекрсивно сколько у нас всего уровней
        levelsCounter (parent, collection) {
            parent.forEach((item) => {
                collection.add(item.level)
                item.items?.length > 0 && (this.levelsCounter(item.items, collection))
            })
        },
        adaptDataToLevel (data, header) {
            return {
                items: data.items,
                header: header,
                parent: data
            }
        },
        /* В связи с тем что watch и computed не могут отследить изменение
        массива пришлось делать отдельный метод для обновления текущего уровня
        вложенности */
        updateCurrentLevel () {
            this.currentLevel = this.levelMap[this.levelMap.length - 1]
        }
    }
}
</script>

<style lang="scss">
@import "../vars.scss";

.navigation-mobile-body {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-top: $token-spacers-l;

    &__submenu {
        height: 100%;
        flex-grow: 1;
    }

    &__submenu-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__footer {
        padding-top: $token-spacers-xs;
        margin-top: auto;
    }

    &__arrow {
        height: 24px;
        width: 24px;

        &--right {
            margin-left: 0.25em;
        }

        &--left {
            margin-right: 0.25em;
        }
    }

    &__list {
        padding: 0;
        margin: 0;

        &--top {
            padding: 1rem 0;
        }
    }

    &__item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        &:first-child {
            .navigation-mobile-body__item-inner {
                padding-top: 0;
            }
        }

        .navigation-mobile-body__item-inner {
            color: $nav-link-color;
        }

        &--backlink {
            margin-bottom: $token-spacers-m;

            .navigation-mobile-body__item-inner {
                @include typo-level(M);
                text-transform: none;
                justify-content: flex-start;
                padding: 0;
                border-bottom: none;
                font-family: $primaryFont;
            }

            .z-icon {
                width: 16px;
                height: 16px;
            }
        }

        &.is-active {
            span,
            .navigation-mobile-body__item-inner {
                color: $nav-link-color-active;
                font-weight: 800;

                .z-icon {
                    path {
                        fill: $nav-link-color-active;
                    }
                }
            }
        }

        &--parent {
            .navigation-mobile-body__item-inner {
                color: $nav-parent-link-color;
                @include typo-level(3XL);
                font-family:$primaryFont
            }
        }

        &--submenu {
            .navigation-mobile-body__item-inner {
                padding-left: 0;
            }
        }
    }

    &__item-inner {
        flex: 0 0 100%;
        max-width: 100%;
        line-height: 1;
        @include typo-level(3XL);
        font-family: $primaryFont;
        font-weight: 700;
        padding: $token-spacers-2-xs 0;
        color: $nav-link-color;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .z-icon {
            path {
                fill: $nav-link-color;
            }
        }

        .navigation-mobile-body__external-icon {
            path {
                fill: $nav-external-icon-color;
            }
        }

        &--redirect {
            cursor: auto;
        }
    }

    .contacts {
        .z-link {
            font-size: 24px;
        }
    }
}

</style>
