<template>
    <a
        :href="link"
        :class="classObject"
        ref="card"
    >
        <div class="news-card__content">
            <div class="news-card__title" v-html="title"></div>
            <div class="more-button">
                <span class="more-button__text">Читать дальше</span>
                <span class="more-button__arrow"></span>
            </div>
            <div class="news-card__date">{{ formatDate(date) }}</div>
        </div>
    </a>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { TimelineLite } from 'gsap'
import { momentFilter } from '@/utils/filter'

const card = ref(null)

const props = defineProps({
    fullHeight: {
        type: Boolean,
        default: false
    },
    link: {
        type: String,
        required: true
    },
    title: {
        type: String,
        required: true
    },
    date: {
        type: Number,
        required: true
    }
})

const classObject = computed(() => {
    return [
        'news-card',
        {
            'news-card--full-height': props.fullHeight
        }
    ]
})

const formatDate = (date) => {
    return momentFilter(date, 'LL')
}

const createHover = (card) => {
    const arrow = card.querySelector('.more-button')
    const arrowArrow = arrow.querySelector('.more-button__arrow')
    const arrowText = arrow.querySelector('.more-button__text')
    const tl = new TimelineLite({ paused: true, reversed: true })

    tl.to(arrowArrow, {width: '100%', duration: 0.2})
    tl.to(arrowArrow, {'--opcaityVal': 0, duration: 0.1})
    tl.to(arrowText, {opacity: 1, duration: 0.1})

    card.addEventListener('mouseenter', function(){
        tl.play()
    })

    card.addEventListener('mouseleave', function(){
        tl.reverse()
    })
}

onMounted(() => {
    createHover(card.value)
})
</script>

<style lang="scss">
.news-card {
    $parent: &;
    @extend %card-decor;

    text-decoration: none;
    display: block;
    position: relative;
    overflow: hidden;
    transition: color $transition;
    background: $token-colors-white;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background: $token-colors-bg-20;
        transform: translateX(-101%);
        transition: transform .5s;
    }

    &__content {
        padding: $token-spacers-l;
        z-index: 2;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;

        @include breakpoint(v-tablet) {
            padding: $token-spacers-s $token-spacers-2-xs;
        }
    }

    &__title {
        font-family: $primaryFont;
        @include typo-level (XL);
        font-weight: 700;
        flex-grow: 1;
    }

    &__date {
        font-size: 14px;
        color: $token-colors-bg-20;
        margin-bottom: 0;
        margin-top: 26px;
        transition: color $transition;
    }

    .more-button {
        --opcaityVal: 1;

        display: flex;
        align-items: center;
        width: max-content;
        position: relative;

        &__arrow {
            position: absolute;
            width: 60px;

            @include breakpoint (mobile) {
                width: 50px;
            }

            &:before {
                content: '';
                height: 2px;
                width: 100%;
                background-color: $token-colors-primary;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                opacity: var(--opcaityVal);
            }

            &:after {
                content: '';
                display: block;
                border: solid $token-colors-primary;
                border-width: 2px 2px 0 0;
                position: relative;
                width: 18px;
                height: 18px;
                transform: translateX(-2px) rotate(45deg);
                margin-left: auto;

                @include breakpoint (mobile) {
                    width: 14px;
                    height: 14px;
                }
            }
        }

        &__text {
            display: flex;
            align-items: center;
            position: relative;
            opacity: 0;
            padding-right: 24px;
        }
    }

    &:hover {
        color: #fff;

        .news-card__date {
            color: white;
        }

        &:before {
            transform: translateX(0);
        }
    }

    &--full-height {
        height: 100%;
    }
}
</style>
