<template>
    <div
        class="z-accordion-item"
        :class="accItemClasses"
    >
        <a
            @click="toggleItem"
            :href="`#${ITEM_ID}`"
            class="z-accordion-item__header"
        >
            <div v-if="hasSlot('custom-icon')" class="z-accordion-item__custom-icon-wrapper">
                <slot name="custom-icon"/>
            </div>
            <template v-if="showFolderIcon && !hasSlot('custom-icon')">
                <template v-if="haveFolders">
                    <z-icon class="z-accordion-item__header-icon" name="accordions/folders" width="24" height="24"
                            key="folders" color="#FF4171"/>
                </template>
                <template v-else>
                    <z-icon class="z-accordion-item__header-icon" name="accordions/folder-simple" width="24" height="24"
                            key="folder" color="#FF4171"/>
                </template>
            </template>
            <span class="z-accordion-item__header-button">
                <z-icon v-if="state" name="accordions/minus" width="24" height="24"/>
                <z-icon v-else name="accordions/plus" width="24" height="24"/>
            </span>
            <slot name="header"/>
        </a>
        <transition
            @enter="animationEnter"
            @leave="animationLeave"
        >
            <div v-show="state" class="z-accordion-item__body">
                <slot name="body"/>
            </div>
        </transition>
    </div>
</template>

<script setup>
import {provide, inject, ref, watch, defineProps, onMounted, computed, useSlots} from 'vue'
import Velocity from 'velocity-animate'
import ZIcon from '../ZIcon/ZIcon.vue'

const props = defineProps({
    id: {
        type: String,
        required: true
    },
    hideLine: {
        type: Boolean,
        default: false
    },
    showFolderIcon: {
        type: Boolean,
        default: false
    },
    verticalPadding: {
        type: Boolean,
        default: false
    },
    theme: {
        type: String,
        validator: prop => ['alternate'].includes(prop)
    },
    hideBorder: {
        type: Boolean,
        default: false
    },
    topBorder: {
        type: Boolean,
        default: false
    }
})

const HASH = window.location.hash
const ITEM_ID = `accordion-${props.id}`
const DURATION = 300

const state = ref(false)
const haveFolders = ref(false)

const accItemClasses = computed(() => {
    return {
        'z-accordion-item--opened': state.value,
        'z-accordion-item--hide-line': props.hideLine,
        'z-accordion-item--top-line': props.bottomBorder,
    }
})

const slots = useSlots()
const hasSlot = (name) => {
    return !!slots[name]
}

const {openedItem, changeOpenedItem} = inject('accordion-item')

const animationEnter = (el, done) => {
    Velocity(el, 'slideDown', {duration: DURATION}, {complete: done})
}

const animationLeave = (el, done) => {
    Velocity(el, 'slideUp', {duration: DURATION}, {complete: done})
}

const toggleItem = () => {
    changeOpenedItem(props.id)
}

const nestedHandler = () => {
    if (!openedItem.value.has(props.id)) {
        changeOpenedItem(props.id)
    }
}

const handleFolders = () => {
    haveFolders.value = true
}

provide('nested', nestedHandler)
provide('folders', handleFolders)

watch(openedItem.value, () => {
    state.value = openedItem.value.has(props.id)
})

onMounted(() => {
    if (HASH === `#accordion-${props.id}`) {
        changeOpenedItem(props.id)
    }
})
</script>

<style lang="scss" src="./index.scss"></style>
