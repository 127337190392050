<template>
    <div class="z-hero">
        <div class="z-hero__wrapper">
            <slot v-if="this.$slots['top']" name="top" />
            <z-caption tag="h1" size="4xl" weight="900"><span v-html="currentTitle"/></z-caption>
            <slot v-if="this.$slots['bottom']" name="bottom" />
        </div>
        <div
            class="z-hero__image"
            v-if="image"
            :style="`background-image: url(${image}); background-position: ${position};`"
        ></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'z-hero',
    props: {
        title: {
            type: String,
            default: ''
        },
        image: {
            type: String
        },
        position: {
            type: String
        }
    },
    computed: {
        ...mapGetters([
            'heroTitle',
            'heroSrc'
        ]),
        currentTitle () {
            if (this.heroTitle) return this.heroTitle
            return this.title
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
