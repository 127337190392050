<template>
    <div class="spoiler" :class="{'spoiler--expanded': expanded}">
        <div class="spoiler__content">
            <slot />
        </div>
    </div>
    <button class="spoiler__trigger" :class="className" @click="toggleHandler">
        {{ expanded ? spoilerText.hide : spoilerText.show }}
    </button>
</template>

<script setup>
import { localize } from '@/utils/i18n'
import {ref} from 'vue'
import { gsap } from 'gsap'

const expanded = ref(false)

const props = defineProps({
    className: {
        type: String
    },
    spoilerText: {
        type: Object,
        default: () => ({
            hide: localize({
                ru: 'Скрыть',
                en: 'Hide'
            }),
            show: localize({
                ru: 'Показать еще',
                en: 'Show more'
            })
        })
    },
    idContainer: {
        type: String,
    }
})

const toggleHandler = () => {
    const state = expanded.value
    expanded.value = !expanded.value

    let offset = 0

    if (state) {
        if (props.idContainer) {
            const container = document.querySelector(`#${props.idContainer}`)

            if (container) {
                offset = container.getBoundingClientRect().top + window.pageYOffset - 32
            }
        }

        gsap.to(window, {duration: 0.5, scrollTo: offset});
    }
}

</script>

<style lang="scss" scoped>
.spoiler {
    width: 100%;
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    transition: grid-template-rows 1s ease;
    $parent: &;

    &__button {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        @include margin-level(top, XS);
    }

    &__content {
        min-height: 0;
        transition: visibility 1s ease;
        visibility: hidden;
        margin-top: $token-spacers-s;
    }

    &--expanded {
        grid-template-rows: 1fr;

        #{$parent}__content {
            visibility: visible;
        }
    }
}
</style>
