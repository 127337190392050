<template>
    <footer class="footer" id="contacts">
        <div class="container">
            <div class="footer__wrapper">
                <div class="row">
                    <div class="col-default-12">
                        <div class="row">
                            <div class="col-default-3 col-laptop-4 col-tablet-5 col-v-tablet-12">
                                <div class="footer__left">
                                    <div class="footer__logo">
                                        <z-image src="/images/logo/logo-footer.svg"></z-image>
                                    </div>
                                    <div class="footer__contacts contacts">
                                        <z-link href="tel:+74956442848" theme="dark" :underlined="false" class="u-bold" size="xl">+7 (495) 644-28-48</z-link>
                                        <span class="contacts__worktime">Пн. — пт.: 10:00-19:00 по Москве</span>
                                        <div class="contacts__socials socials-list">
                                            <a class="socials-list__item" href="https://t.me/mvideoeldorado_live" target="_blank">
                                                <z-icon name="social/telegram" width="40" height="40"/>
                                            </a>
                                            <a class="socials-list__item" href="https://habr.com/ru/companies/mvideo/articles/" target="_blank">
                                                <z-icon name="social/habr" width="40" height="40"/>
                                            </a>
                                            <a class="socials-list__item" href="https://vc.ru/mvideoeldorado/" target="_blank">
                                                <z-icon name="social/vc" width="40" height="40"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-default-8 col-default-offset-1 col-laptop-7 col-tablet-7 col-tablet-offset-0 col-v-tablet-12">
                                <div class="footer__right">
                                    <div class="other-sites">
                                        <div class="other-sites__title">Корпоративные сайты</div>
                                        <div class="other-sites__list">
                                            <a href="https://mvideoeldorado.ru/ru/" class="other-sites__link" target="_blank">
                                                <img src="/images/footer/group.svg">
                                            </a>
                                            <a href="https://www.mvideo.ru/" class="other-sites__link" target="_blank">
                                                <img src="/images/footer/mvideo.svg">
                                            </a>
                                            <a href="https://www.eldorado.ru/" class="other-sites__link" target="_blank">
                                                <img src="/images/footer/eldorado.svg">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-default-12">
                        <div class="row">
                            <div class="col-default-3 col-tablet-12 u-flex--row footer-copyright">
                                <z-copyright
                                    year-current
                                    text-after="ООО «М Тех». Все права защищены."
                                ></z-copyright>
                            </div>
                            <div class="col-default-8 col-default-offset-1 col-tablet-offset-0 col-tablet-12 footer-description">
                                <p class="footer-description__title">Электронный документооборот (ЭДО)</p>
                                <p class="footer-description__text">ООО &laquo;М&nbsp;Тех&raquo; использует
                                    <z-tooltip icon-hidden trigger="click" max-width="none" :arrow="false" interactive>
                                        <template v-slot:content>
                                            <z-list size="m" class="u-collapse--bottom">
                                                <z-list-item>Веб-сервис «СБИС» компании ООО «Тензор»;</z-list-item>
                                                <z-list-item>«Такском Файлер» компании ООО «Такском»;</z-list-item>
                                                <z-list-item>«Диадок» компании ЗАО «ПФ «СКБ КОНТУР»;</z-list-item>
                                                <z-list-item>«Сфера» компании ООО «КОРУС Консалтинг СНГ»;</z-list-item>
                                                <z-list-item>«Астрал.ЭДО» компании АО «Калуга Астрал».</z-list-item>
                                            </z-list>
                                        </template>
                                        <z-link theme="dark" href="javascript:void(0)">электронный документооборот (ЭДО)</z-link>
                                    </z-tooltip> для ускорения и&nbsp;повышения эффективности процедур обмена юридически значимыми документами с&nbsp;партнёрами. При подписании документов используется усиленная квалифицированная электронная подпись в&nbsp;соответствии с&nbsp;Федеральным законом от&nbsp;06.04.2011&nbsp;N 63-ФЗ &laquo;Об&nbsp;электронной подписи&raquo;.</p>
                                <p class="footer-description__text">Переход на&nbsp;ЭДО с&nbsp;компанией ООО &laquo;М&nbsp;Тех&raquo; возможен на&nbsp;основании соглашения-оферты. Ознакомиться с&nbsp;офертой можно <z-link target="_blank" href="/files/oferta.pdf" theme="dark">по&nbsp;ссылке</z-link>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>
</script>

<style lang="scss">
.footer {
    background: $token-colors-form-bg-30;

    &__logo {
        max-width: 66px;
        flex-shrink: 0
    }

    &__wrapper {
        @include padding-level (top, 4XL);
        @include padding-level (bottom, 4XL);
    }

    &__left {
        display: flex;
        align-items: flex-start;
    }

    &__contacts {
        margin-right: auto;
        padding-left: $token-spacers-l;
        flex-shrink: 0;

        @include breakpoint (mobile) {
            padding-left: $token-spacers-xs;
        }
    }

    .other-sites {
        &__title {
            color: $token-colors-black-60;
            font-weight: 400;
            font-size: 16px;
            margin-bottom: $token-spacers-2-xs;
        }

        &__list {
            display: flex;

            @include breakpoint (mobile) {
                flex-direction: column;
            }
        }

        &__link {
            display: inline-block;
            margin-right: $token-spacers-l;

            @include breakpoint (mobile) {
                margin-right: 0;

                + .other-sites__link {
                    margin-top: $token-spacers-s;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .z-copyright {
        align-self: flex-end;
    }

    .row {
        margin-top: -$token-spacers-l;

        > [class*=col-] {
            margin-top: $token-spacers-l;
        }
    }

    .footer-description {
        @include breakpoint (v-tablet) {
            margin-top: $token-spacers-2-xs !important;
        }

        &__title {
            color: $token-colors-black-60;
            font-size: 16px;
            margin-bottom: 10px;
        }

        &__text {
            color: $token-colors-black-60;
            font-size: 14px;
            margin-bottom: $token-spacers-xs;

            @include breakpoint (laptop) {
                font-size: 12px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .footer-copyright {
        @include breakpoint (v-tablet) {
            margin-top: $token-spacers-s !important;
        }
    }
}

.socials-list {
    display: flex;

    &__item {
        margin-right: $token-spacers-xs;
        width: 40px;
        height: 40px;
        display: inline-block;
        transition: background $transition;

        &:last-child {
            margin-right: 0;
        }

        path {
            transition: fill $transition;
        }

        &:hover {
            background: $token-colors-white;

            path {
                fill: $token-colors-black-60;
            }
        }
    }
}

.contacts {
    .z-link {
        text-decoration: none;
        font-family: $primaryFont;
    }

    &__worktime {
        display: block;
        margin-top: $token-spacers-3-xs;
        color: $token-colors-black-95;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.3;
    }

    &__socials {
        margin-top: $token-spacers-2-xs;
    }
}
</style>
