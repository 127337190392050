<template>
    <div :class="classObject">
        <a
            @click="toggleItem($event)"
            class="accordion-link__header"
        >
            <span class="accordion-link__item-inner" :class="{'accordion-link__item-inner--active': itemParent.active}">
                <slot/>
                <z-icon :name="arrowIcon" dir="right"/>
            </span>
        </a>
        <transition
            @enter="animationEnter"
            @leave="animationLeave"
        >
            <div v-show="state" class="accordion-link__body">
                <ul>
                    <li class="accordion-link__body-item" v-if="!itemParent.pageParams.redirect">
                        <a class="accordion-link__body-link" :href="itemParent.link" v-html="itemParent.name"/>
                    </li>
                    <li class="accordion-link__body-item"
                        v-for="(item, index) in itemParent.items"
                        :key="`sublink-${itemParent.name}-${itemParent.level}-${index}`">
                        <a class="accordion-link__body-link" :href="item.link" v-html="item.name"/>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
import Velocity from 'velocity-animate'

export default {
    name: 'accordion-link',
    props: {
        itemParent: {
            type: Object,
            required: true
        },
        arrowIcon: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            state: false,
            duration: 300
        }
    },
    methods: {
        toggleItem (e) {
            this.state = !this.state

            if (!this.state && e) e.preventDefault()
        },
        animationEnter (el) {
            Velocity(el, 'slideDown', { duration: this.duration })
        },
        animationLeave (el) {
            Velocity(el, 'slideUp', { duration: this.duration })
        },
        scrollIntoView (el) {
            setTimeout(() => {
                el.scrollIntoView({behavior: 'smooth', block: 'start'})
            }, 301)
        }
    },
    computed: {
        classObject () {
            let arrClass = [
                'accordion-link',
                {
                    'accordion-link--opened': this.state
                }
            ]

            return arrClass
        }
    }
}
</script>

<style lang="scss">
@import "../vars";

.accordion-link {
    width: 100%;
    $parent: &;

    &__item-inner {
        flex: 0 0 100%;
        max-width: 100%;
        line-height: 1;
        @include typo-level(3XL);
        font-family: $primaryFont;
        padding: $token-spacers-2-xs 0;
        color: $nav-link-color;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .z-icon {
            width: 24px;
            height: 24px;
            path {
                fill: $nav-link-color;
            }
        }

        &--active {
            color: $nav-link-color-active;
            .z-icon {
                path {
                    fill: $nav-link-color-active;
                }
            }
        }
    }

    .z-icon {
        transition: transform ease 300ms;
    }

    &--opened {
        #{$parent}__item-inner {
            color: $nav-link-color-active;
        }
        .z-icon {
            transform: rotate(0deg);
            path {
                fill: $nav-link-color-active;
            }
        }
    }

    &__body {
        @include padding-level(left, XS);
    }

    &__body-item {
        @include typo-level(XL);
        font-family: $primaryFont;
    }

    &__body-link {
        display: block;
        padding: $token-spacers-2-xs 0;
    }
}
</style>
