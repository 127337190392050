<template>
    <div v-if="data" class="bio-detail">
        <div class="bio-detail__info">
            <div class="bio-detail__avatar-wrapper">
                <div class="bio-detail__avatar" :style="`background-image: url('${avatar}')`"/>
            </div>
            <div>
                <z-caption
                    class="u-bottom-margin--2xs-important"
                    tag="h2"
                    size="2xl"
                    weight="700"
                >{{data.name}}</z-caption>

                <p
                    v-if="data.position.value"
                    v-html="data.position.value"
                ></p>

                <p
                    v-if="data.appointmentDate.value"
                    v-html="data.appointmentDate.value"
                ></p>
                
                <div
                    v-if="data.previewText"
                    v-html="data.previewText"
                ></div>
                
            </div>
        </div>
        <div>
            <div class="bio-detail__item" v-if="data.education.value">
                <p class="bio-detail__key">Образование: </p>
                <p class="bio-detail__value" v-html="data.education.value"></p>
            </div>
            <div class="bio-detail__item" v-if="data.experience.value">
                <p class="bio-detail__key">Опыт работы: </p>
                <p class="bio-detail__value" v-html="data.experience.value"></p>
            </div>
            <div
                v-if="data.detailText"
                v-html="data.detailText"
            ></div>
        </div>
        <z-page-nav :data="nav" button-text="Вернуться к&nbsp;списку"/>
    </div>
</template>

<script>
export default {
    name: 'bio-detail',
    props: {
        source: {
            type: String,
            default: 'bio-detail'
        }
    },
    data () {
        return {
            data: this.$root.app.components[this.source]?.item || null,
            nav: this.$root.app.components[this.source].nav
        }
    },
    computed: {
        avatar () {
            if (this.data.detailPicture?.link) {
                return this.data.detailPicture.link
            }

            return this.data.gender.xmlId === 'female' ? '/images/female.jpg' : '/images/male.jpg'
        }
    }
}
</script>

<style lang="scss">
.bio-detail {
    &__info {
        display: flex;

        @include margin-level(bottom, XL);

        @include breakpoint(mobile) {
            flex-direction: column;
        }
    }

    &__avatar-wrapper {
        max-width: 313px;
        width: 100%;
        @include margin-level(right, L);

        @media screen and (max-width: 850px) {
            max-width: 200px;
        }

        @include breakpoint(mobile) {
            max-width: 100%;
            margin-right: 0;
            margin-bottom: $token-spacers-xs;
        }
    }

    &__avatar {
        width: 100%;
        padding-top: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 4px;
    }

    &__position {
        display: block;
        font-weight: 400;
        @include typo-level(S);
        line-height: 1.4;
        color: $token-colors-black-40;

        @include margin-level(bottom, L);
    }

    &__item {
        &:not(:last-child) {
            @include margin-level(bottom, 2XS)
        }
    }

    &__key {
        font-weight: 400;
        @include typo-level(S);
        line-height: 1.4;
        color: $token-colors-black-60;
    }

    &__value {
        font-weight: 400;
        @include typo-level(S);
        line-height: 140%;
        color: $token-colors-black-90;
    }

    &__rewards {
        border-bottom: 1px solid $token-colors-black-10;
        @include padding-level(bottom, 3XL);
        @include margin-level(bottom, S);
    }

    &__reward {
        &:not(:last-child) {
            @include margin-level(bottom, 2XS);
        }
    }
}
</style>
