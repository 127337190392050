<template>
    <transition
        @before-enter="appearAnimationBefore"
        @enter="appearAnimationEnter"
        @leave="appearAnimationLeave"
    >
        <div
            class="cookie-popup"
            v-if="visible"
        >
            <button class="cookie-popup__close-button">
                <z-icon name="tools/close" width="24" height="24" icon-color="#0D3061" @click="hideCookiePopup"/>
            </button>
            <div class="cookie-popup__body">
                <z-caption class="u-bottom-margin--xs-important cookie-popup__title" tag="h2" size="3xl" weight="700">{{ locales.text.title }}</z-caption>
                <vue-raw class="cookie-popup__text" :raw="locales.text.description"/>
                <template v-if="visibleSettings">
                    <div class="cookie-popup__settings">
                        <div v-for="item in settings" class="cookie-popup__card-setting" :key="item.id">
                            <div class="cookie-popup__card-header">
                                <z-caption class="cookie-popup__card-title" size="l" @click="showInfo(item.id)">{{item.title}}</z-caption>
                                <z-switch :id="item.id" :check="item.checked" :disabled="item.locked" @change="switchHandler"/>
                            </div>
                            <p v-if="!currentDevice || settingInfo[item.id]" class="cookie-popup__card-description" v-html="item.description"/>
                        </div>
                    </div>
                </template>
                <div class="cookie-popup__buttons">
                    <div class="cookie-popup__button">
                        <z-button @click="submit">
                            {{ locales.text.button.agree }}
                        </z-button>
                    </div>
                    <div class="cookie-popup__button" v-if="includeSettings">
                        <z-button type="button" @click="showSettings">
                            {{ locales.text.button.more }}
                        </z-button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue';
import locales from './locales'
import cookie from 'cookie_js'
import {useGetter} from '@/composable/useGetter'
import {useCookieSettings} from './composable/useCookieSettings';
import {useCookieAnimation} from './composable/useCookieAnimation';

defineProps({
    includeSettings: {
        type: Boolean,
        default: true
    }
})

const DURATION = 500;
const settingInfo = ref({
    mandatory: false,
    operational: false,
    functional: false,
    marketing: false
})
const visible = ref(false)
const visibleSettings = ref(false)
const operational = ref(true)
const functional = ref(true)
const marketing = ref(true)

const {device} = useGetter(['device'])

onMounted(() => {
    visible.value = !cookie.get('cookies_policy')
    operational.value = !cookie.get('operational')
    functional.value = !cookie.get('functional')
    marketing.value = !cookie.get('marketing')
})

const settings = useCookieSettings(operational.value, functional.value, marketing.value)
const {appearAnimationBefore, appearAnimationEnter, appearAnimationLeave} = useCookieAnimation(DURATION)

const currentDevice = computed(() => device.value === 'mobile')
const showInfo = (item) => {
    if (currentDevice) {
        settingInfo.value[item] = !settingInfo.value[item]
    } else {
        return ''
    }
}

const switchHandler = (data) => {
    switch (data.id) {
    case ('operational'): {
        operational.value = data.value
        break
    }
    case ('functional'): {
        functional.value = data.value
        break
    }
    case ('marketing'): {
        marketing.value = data.value
        break
    }
    default: {
        break
    }
    }
}
const hideCookiePopup = () => {
    visible.value = false
}
const showSettings = () => {
    visibleSettings.value = !visibleSettings.value
}
const submit = () => {
    cookie.set({
        cookies_policy: true,
        operational: operational.value,
        functional: functional.value,
        marketing: marketing.value,
    }, {
        domain: '.' + location.host,
        path: '/',
        expires: 365
    })
    hideCookiePopup()
}
</script>

<style lang="scss" src="./index.scss"></style>
