export const dataCases = [
    {
        id: 'kpi',
        image: '/images/mainpage/cases-card-image-1.jpg',
        title: 'Цифровая система мотивации сотрудников Sales KPI',
        subtitle: 'Sales KPI &shy;&mdash; продукт на&nbsp;стыке&nbsp;HR, технологий и&nbsp;ритейла. Сервис реализован в&nbsp;формате приложения с&nbsp;геймификацией.',
        descripton: 'Это прозрачная система отслеживания основных KPI магазинов и&nbsp;достижений сотрудников в&nbsp;режиме реального времени. Персонал магазинов получает возможность изучать и&nbsp;совершенствовать собственные показатели, увеличивая доход, а&nbsp;руководители&nbsp;&mdash; мониторить динамику торговых точек с&nbsp;возможностью отображения конкретного вклада сотрудников. В&nbsp;приложении реализована оперативная обратная связь по&nbsp;продажам, подсказки по&nbsp;достижению плана, детализация чеков, рейтинг персонала, отслеживание прогресса, а&nbsp;также добавлен функционал по&nbsp;адаптации новых сотрудников. Проект позволяет увеличить эффективность линейных сотрудников, повысить осведомленность и&nbsp;вовлеченность персонала, улучшить сервис для покупателей, а&nbsp;также сократить трудозатраты руководителей и&nbsp;значительно увеличить выручку магазинов.'
    },
    {
        id: 'exchange',
        image: '/images/mainpage/cases-card-image-2.jpg',
        title: 'Маркетплейс рабочего времени &laquo;Биржа смен&raquo;',
        subtitle: '&laquo;Биржа смен&raquo;&nbsp;&mdash; это первый автоматизированный шеринг внутреннего персонала в&nbsp;ритейле, реализованный совместно с&nbsp;Verme.',
        descripton: '&laquo;Биржа смен&raquo; соединяет магазины, которым нужны временные сотрудники, и&nbsp;штатных сотрудников, частично занятых на&nbsp;основном рабочем месте, при этом заинтересованных в&nbsp;гибком заработке или новых профессиях. Любой магазин может выставить на&nbsp;&laquo;биржу&raquo; свободные смены, которые могут забронировать сотрудники других магазинов в&nbsp;рамках одного города. При достаточной квалификации сотрудники могут бронировать смены в&nbsp;отличной от&nbsp;своей основной роли специализации. Например, консультант может работать сервисным инженером. Нет необходимости перемещать сотрудника в&nbsp;организационной структуре, а&nbsp;все результаты автоматически относятся к&nbsp;тому магазину, где работал сотрудник. При бронировании специалист сразу видит стоимость смены, а&nbsp;после того, как смена отработана, получает автоматический расчет. Благодаря &laquo;Бирже смен&raquo; сотрудники получают выгодную подработку, а&nbsp;компания&nbsp;&mdash; квалифицированный временный персонал.'
    },
    {
        id: 'titan',
        image: '/images/mainpage/cases-card-image-4.jpg',
        title: 'Титан',
        subtitle: '&laquo;Титан&raquo;&nbsp;&mdash; ИТ-продукт для планирования закупок, прогнозирования продаж и&nbsp;распределения товаров для ритейла.',
        descripton: 'Решение разработано с&nbsp;учётом специфики торговли &shy;&mdash; ее&nbsp;легко адаптировать к&nbsp;внешним система и&nbsp;поддерживать собственными силами без вендорской поддержки. &laquo;Титан&raquo; обходится дешевле зарубежных SaaS-решений при значительном увеличении скорости и&nbsp;точности производимых им&nbsp;расчетов. В&nbsp;ежедневном бизнес-ритме может эффективно использоваться сценарное прогнозирование с&nbsp;учетом сезонного трафика, желаемого уровня сервиса, то&nbsp;есть, необходимой скорости получения клиентом покупки, географии каждого объекта и&nbsp;имеющейся динамики каналов продаж. Решение производит необходимые расчеты ежедневно в&nbsp;течение 1,5-2&nbsp;часов, что позволяет не&nbsp;допускать перестока на&nbsp;объектах или слишком длительных сроков доставки&nbsp;&mdash; по&nbsp;некоторым регионам сроки поставок с&nbsp;центрального склада могут сократиться вдвое. &laquo;Титан&raquo; учитывает потребности клиентов и&nbsp;бизнеса, выявленные математическими алгоритмами на&nbsp;основе анализа данных, а&nbsp;вся разработка и&nbsp;расчеты производятся в&nbsp;облачных средах.'
    },
    {
        id: 'comspase',
        image: '/images/mainpage/cases-card-image-5.jpg',
        title: 'Коммерческо-логистическая платформа ComSpacе',
        subtitle: 'Компания разрабатывает и&nbsp;внедряет комплекс решений на&nbsp;основе AI-алгоритмов для управления товарными категориями на&nbsp;всех этапах коммерческого и&nbsp;логистического планирования. ',
        descripton: 'Собственная интегрированная платформа управления товарными категориями ComSpace на&nbsp;базе алгоритмов машинного обучения формирует оптимальный ассортимент каждого магазина, ежедневно корректирует цены с&nbsp;учетом действий конкурентов и&nbsp;рассчитывает оптимальный объем закупки и&nbsp;пополнения стоков. Платформа объединяет четыре in-house дата-продукта, которые заменили вендорские решения в&nbsp;ее&nbsp;ИТ-ландшафте: управление ассортиментом (&laquo;Оптимизатор&raquo;); управление товарными запасами (&laquo;Титан&raquo;); ценообразование и&nbsp;промо-планирование'
    },
]
